.b-devices {
  &__h {
    display: inline-block;
    font-size: 18px;
    font-weight: 200;
    color: #6b8091;
    margin-bottom: 15px;
  }
  &__items {
    //
  }
  &__item {
    border-bottom: 1px solid #aaa;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  &__item-col {
    margin-right: 15px;
  }
  &__remove-btn {
    color: #d73925;
    border: none;
    background-color: transparent;
    padding: 0 2px;
    font-size: 18px;
    margin-left: 5px;
    margin-right: 5px;
    opacity: .85;
    &:hover {
      opacity: 1;
    }
  }
  &__nodata {
    color: #aaa;
  }
  .btn {
    border-radius: 0;
  }
}
