.nav-custom {
  background: $white;
  color: $color-dark-blue;
  border-bottom: 0;
  padding: 10px 35px;
  & > li {
    a {
      color:  $color-light-gray-3;
      border: none;
      border-bottom:0;
      font-size: 12px;
      padding: 8px;
      &:hover,
      &:focus {
        border: none;
        border-radius: 0;
        border-bottom:0;
        background: #fff;
        color:  $color-blue-default;
      }

    }

    &.active {
      a {
        border: none;
        border-radius: 0;
        border-bottom:0;
        background: #fff;
        color: $color-blue-default;
        &:hover,
        &:focus {
          border: none !important;
          border-radius: 0 !important;
          border-bottom:0 !important;
          background: #fff !important;
          color: $color-blue-default !important;
        }
      }
    }
  }
}

.nav-tabs > li.active > a:focus {
  color: #555555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}

.tab-content-custom {
  background: #fff;
}

.w-less-850{
  .nav-custom {
    padding-top:10px;
    padding-bottom: 10px;
    & > li {
      a {
        //border-bottom:1px solid transparent;
        &:hover,
        &:focus {
          //border-bottom:1px solid $color-dark-blue;
        }
      }
      &.active {
        a {
          //border-bottom:1px solid $color-dark-blue;
          &:hover,
          &:focus {
            //border-bottom:1px solid $color-dark-blue;
          }
        }
      }
    }
  }
}

@media (max-width: 767px){
  .nav-custom {
    padding-top:10px;
    padding-bottom: 10px;
    & > li {
      a {
        border-bottom:1px solid transparent;
        &:hover,
        &:focus {
          border-bottom:1px solid $color-dark-blue;
        }
      }
      &.active {
        a {
          border-bottom:1px solid $color-dark-blue;
          &:hover,
          &:focus {
            border-bottom:1px solid $color-dark-blue;
          }
        }
      }
    }
  }
}