.sweet-alert button{
  font-size: 13px !important;
}
.sweet-alert p{
  text-align: left;
  color:black;
  font-size:12px
}
.sweet-alert button.cancel{
  background-color: #22B0F3 !important;
}



.main-header {
  height: 106px !important;

  @media (max-width: 767px) {
    &.no-auth-header {
        height: 56px !important;
    }
  }
}
@media(max-width: 767px){
  .wrapper{
    height:inherit
  }
}

.odd{
  background-color: #dedddd ;
}
.even{
  background-color: white;
}
.scroll-block {
  overflow-x: hidden;
}
.box{
  border-top: 0px !important;
  box-shadow: none;
}
.content-wrapper {
  position: relative;
  height: calc(100% - 106px);
  min-height: auto !important;
  overflow: auto;
  z-index: auto !important;
}

@media (min-width: 768px) {
  .main-header {
    height: 86px !important;
  }

  .content-wrapper {
    height: calc(100% - 86px);
  }
}

.row-flex, .row-flex > .flex-col {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex:1 1 auto;
}

.row-flex-wrap {
  -webkit-flex-flow: row wrap;
  align-content: flex-start;
  flex:0;
}

.row-flex > .flex-col, .container-flex > .flex-col {
  margin:-.2px; /* hack adjust for wrapping */
}

.container-flex > .flex-col > div,.row-flex > .flex-col > div {
  width:100%;
}

.news_image{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 170px;

}
.media_class{
  background-color: white;
  margin: 0 0 10px 0px;
  padding:10px;

  // fix to prevent false formatting
  .article_content div{
    padding: 0;
    margin: 0;
    min-height: 0;
  }
}
.footer_icons{
  >div{
    a{
      font-size:15px;
      >i{
        margin-right: 5px;
      }
    }
  }
  .publication_logo{
    max-height: 35px;
    margin-top:3px;
  }
  .publication_logo_for_news{
    max-width: 100%;
    float: right;
    max-height: 35px;
  }
}

.publication_logo{
  max-height: 40px;
}

.panel-info{
  border-color: #D2D6DE;
  border-radius: 0px;
  .panel-heading{
    border-color: #D2D6DE;
    background-color: #D2D6DE;
  }
}
.article{
  .author_date{
    font-size:12px;
  }
  .read_more_container{
    text-align: center;
  }
  .read_more{
    display: inline-block;
    width: 100%;
    background-color: #48C8F4;
    text-align: center;
    padding: 15px 15px;
    font-size: 15px;
    color: white;
    &:hover{
      color: white;
    }
  }
  .article_content{
    padding:15px !important;
  }
  .footer_icons{
    margin-top:25px;
  }
  .panel-heading{
    padding: 5px;
    min-height:15px;
    color:black;
    .panel-title{
        font-size:13px;
        min-height:0px;
    }
  }
  .panel-body{
    div{
      font-size:13px;
      padding: 2px 0;
      font-family: 'Poppins', sans-serif;
    }
  }
}
.new_header{
  color:black;
}
.article_full_content {
  background-color: white;
  padding: 25px 0 !important;
  margin-bottom: 15px;
}
.title{
  color: black;
}

@media (max-width: 991px) {
  .tags-block {
    padding: 0;
  }
  .articls .title {
    display: inline-block;
    color: #000;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .media_class{
    .text-body{
      padding: 0 10px;
    }
  }
}
@media (max-width: 767px) {
  .read_more_container{
    text-align: center;
    .read_more{
      width: auto;
      padding:5px 15px;
    }
  }
  .mobile_nav{
    div{
      margin:5px;
      background-color: white;
    }
  }
  .box-body{
    padding:0px;
  }
  .news_image{
    height: 60px;
  }
  .media-heading{

    a{
      font-size: 15px;
    }

  }
  .media_class{
    padding:6px;
    margin:4px 0;
    .article_content{
      padding: 0;
    }
    .text-body{
      padding: 0 7px;
    }
  }
  .title{
    color: black !important;
    font-weight: bold;
  }

  .publication_logo_for_news{
    max-height: 13px;
  }
}

.nav-menu-tab-desk{
  width: 100%;
  ul {
    list-style: none;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    li {
      display: inline-block;
      padding: 11px 5px;
      font-size: 12px;
      cursor: pointer;
      a{
        color:black;
      }
    }

    .active-menu-item {
      background-color: white;
      font-weight: bold;
    }
    .last_li{
      margin-left: 0px !important;
    }
  }
}
.nav-menu-tab-mobile {
  width: 100%;

  ul {
    display: inline-block;
    list-style: none;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 5px;

    li {
      display: block;
      float: left;
      background-color: #fff;
      padding: 2px 5px;
      width: 32.5%;
      margin-left: 1.249%;
      font-size: 9px;
      margin-bottom: 2px;
      cursor: pointer;
      a{
        color:black;
      }
    }
    li:nth-of-type(1), li:nth-of-type(4), li:nth-of-type(7) {
      margin-left: 0px !important;
    }
    .active-menu-item {
      background-color: #41c8f2;
      color: #fff;
    }
    .last_li{
      margin-left: 0px !important;
    }
  }
}

@media (max-width: 298px) {
  .nav-menu-tab-mobile li {
    font-size: 6px;
  }
}
@media (max-width: 325px) {
  .nav-menu-tab-mobile li {
    margin-left: 2px !important;
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.content-header {
  padding-top: 20px;
}
.content-header > h1 {
  font-weight: 200;
  color: #6b8091;
}
