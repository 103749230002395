.import-other-inner-block {
  margin-left: 15px;
  border: 1px solid black; 
}
.import-other {
  h5 {
    margin-bottom: 20px;
  }
  .row {
    margin-bottom: 15px;
  }
  :not(#foo) .styled-checkbox + label:before {
    background: #bbe8fb;
  }
}