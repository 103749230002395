#datatable-users, #datatable-buildings, #logs {
    background-color: #fff;
}
#datatable-users .odd, #datatable-buildings .odd, #logs .odd {
    background-color: #ecfbff;
}
.toolbar {
    float: left;
}


.vuetable-custom {
       ul.dropdown-menu li {
       margin-left: 20px;
   }
   .vuetable th.sortable:hover {
       color: #2185d0;
       cursor: pointer;
   }
   .vuetable-actions {
       width: 11%;
       padding: 12px 0px;
       text-align: center;
   }
   .vuetable-actions > button {
       padding: 3px 6px;
       margin-right: 4px;
   }
   .vuetable-pagination {
       margin: 0;
   }
   .vuetable-pagination .btn {
       margin: 2px;
   }
   .vuetable-pagination-info {
       float: left;
       margin-top: auto;
       margin-bottom: auto;
   }
   ul.pagination {
       margin: 0px;
   }
   .vuetable-pagination-component {
       float: right;
   }
   .vuetable-pagination-component li a {
       cursor: pointer;
   }
   [v-cloak] {
       display: none;
   }
   .highlight {
       background-color: yellow;
   }
   .vuetable-detail-row {
       height: 200px;
   }
   .detail-row {
       margin-left: 40px;
   }
   .expand-transition {
       transition: all .5s ease;
   }
   .expand-enter,
   .expand-leave {
       height: 0;
       opacity: 0;
   }
   /* Loading Animation: */
   
   .vuetable-wrapper {
       opacity: 1;
       position: relative;
       filter: alpha(opacity=100);
       /* IE8 and earlier */
   }
   .vuetable-wrapper.vue-loading {
       opacity: 0.4;
       transition: opacity .3s ease-in-out;
       -moz-transition: opacity .3s ease-in-out;
       -webkit-transition: opacity .3s ease-in-out;
   }
   .vuetable-wrapper.vue-loading:after {
       position: absolute;
       content: '';
       top: 40%;
       left: 50%;
       margin: -30px 0 0 -30px;
       border-radius: 100%;
       -webkit-animation-fill-mode: both;
       animation-fill-mode: both;
       border: 4px solid #000;
       height: 60px;
       width: 60px;
       background: transparent !important;
       display: inline-block;
       -webkit-animation: pulse 1s 0s ease-in-out infinite;
       animation: pulse 1s 0s ease-in-out infinite;
   }
   @keyframes pulse {
       0% {
           -webkit-transform: scale(0.6);
           transform: scale(0.6);
       }
       50% {
           -webkit-transform: scale(1);
           transform: scale(1);
           border-width: 12px;
       }
       100% {
           -webkit-transform: scale(0.6);
           transform: scale(0.6);
       }
   }
}


.dataTable{
    margin-top: 0px !important;
    th{
        font-weight: normal;
        vertical-align: top !important;
    }
}
.dataTables_wrapper{
    > .row{
        margin-left: 0px;
        margin-right: 0px;
        .col-sm-12{
            padding: 0px;
        }
    }
}
.box_for_table--dashboard{
    width: 100%;
    margin-top:10px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#dashboard-table{
    border-top:none;
}
table.table-like-agency-bar{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: table;
    table-layout:fixed;
    width: 100%;
}
.table-like-agency-bar{
    td,th{
        min-width:40px;
        text-align: center;
    }
    &__violation{
        width: 8%;
        padding: 2px 18px 2px 4px !important;
        &.-address-col {
            width: 14%;
        }
    }
    &__first-column{
        text-align: left !important;
        a {
            display: inline-block;
            overflow: hidden;
            vertical-align: top;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 98%;
        }
    }
    &__violation--double{
        width: 18%;
        &__children{
            width: 9%;
        }
    }
}
@media (max-width:1400px){
    .table-like-agency-bar{
        &__violation{
            width: 9%;
        }
        &__violation--double{
            width: 19%;
            &__children{
                width: 8%;
            }
        }
    }
}
@media (max-width:991px){
    .table-like-agency-bar{
        &__violation{
            width: 72px !important;
            &.-address-col {
                width: 120px !important;
            }
        }
        &__violation--double{
            width: 150px;
            &__children{
                width: 72px !important;
            }
        }
        &__first-column{
            width: 120px !important;
        }
        &--headers{
            .table-like-agency-bar{
                &__first-column{
                    width: 180px;
                }
                &__violation{
                    width: 79px !important;
                }
                &__violation--double{
                    width: 158px;
                    &__children{
                        width: 79px !important;
                    }
                }
            }
        }
    }
    #dashboard-table{
        .table-like-agency-bar__violation{
            text-align: left;
        }
    }
}

.h-relative-layout__dashboard-box[max-width~="1100px"] {
    .table-like-agency-bar{
        &__violation{
            width: 72px !important;
            &.-address-col {
                width: 120px !important;
            }
        }
        &__violation--double{
            width: 150px !important;
            &__children{
                width: 72px !important;
            }
        }
        &__first-column{
            width: 120px !important;
        }
        &--headers{
            .table-like-agency-bar{
                &__first-column{
                    width: 180px;
                    text-align:left !important;
                }
                &__violation{
                    width: 79px !important;
                }
                &__violation--double{
                    width: 158px !important;
                    &__children{
                        width: 79px !important;
                    }
                }
            }
        }
    }
}


.pp-table {
    background: #fff;
}

table.dataTable.table-like-agency-bar {
    thead .sorting:after,
    thead .sorting_asc::after,
    thead .sorting_desc::after {
        position: absolute;
        top: 4px;
        right: 5px ;
        display: block;
        font-family: 'Glyphicons Halflings';
        opacity: 0.5;
    }
}
