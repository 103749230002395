.gray-box {
    margin-top: 10px;
    border-top: none;
    border-right: 2px solid #d2d6de;
    border-bottom: 2px solid #d2d6de;
    .agency-bar {
        &__left,
        &__right{
            background: transparent;
        }
    }
    &--paddings{
        padding: 30px 20px;
    }
}
