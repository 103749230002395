.pp-datatable {
  position: static;
  zoom: 1;
  width: 100%;
  max-width: 100%;
  .detiles-container{
    .value{
      min-height: 15px;
    }
  }

  .dispHist {
      .head {
          td {
              text-align: left;
              white-space: normal;
          }
      }
  }

  td.type {
    white-space: nowrap;
    text-align: center;
  }
  td.type span {
    background: #ccc;
    border: dotted 1px #ccc;
    color: #666;
    font-size: 10.5px;
    line-height: 10.5px;
    text-transform: uppercase;
    padding: 3px;
    border-radius: 12px;
    display: block;
    max-width: 250px;

    &.green_color {
        background: #86ca5d;
        border-color: #86ca5d;
        color: #fff;
    }

    &.gray_color {
        background: #b3b3b3;
        border-color: #b3b3b3;
        color: #fff;
    }

    &.transparent_color {
        background: transparent;
        border-color: #5b5b5b;
        color: #5b5b5b;
    }
  }

  th {
    &.sortable {
      &:after {
        font-family: FontAwesome;
        content: "\f0dc";
        opacity: 0.6;
        margin-left: 3px;
      }
      &.desc {
        &:after {
          content: "\f0dd";
          opacity: 1;
        }
      }
      &.asc {
        &:after {
          content: "\f0de";
          opacity: 1;
        }
      }

    }

  }

  & > thead th {
    font-size: 11px;
    text-transform: uppercase;
    background: #fff !important;
    color: #666;
    border-bottom: 1px solid #ccc;
    padding: 9px;
  }

  th.expander,
  td.collapsible {
    padding: 0 0 0 8px;
    font-size: 14px;
    max-width: 20px;
    color: #aaa;
  }

  .expand-parent:hover {
     td {
      background: #cfe9f1 !important;
    }

  }

  & > tbody td {
    padding: 11px 9px;
    cursor: default;
    vertical-align: middle;
    color: #5B5B5B;
    font-weight: 500;
    background-color: #FFF;
    //white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.note a {
        color: #666;
        cursor: pointer;
    }
  }

   & > tbody:nth-of-type(2n) td {
    background-color: #f4f4f4;
  }

  div.status {
   &.closed {
     background: #86ca5d;
     border-color: #86ca5d;
   }
   &.hearingcompleted {
     background: #610cbd;
     border-color: #610cbd;
   }
   &.violationclosed {
     background: #86ca5d;
     border-color: #86ca5d;
   }
   &.novsent {
     color: #666;
     background: #FF6;
     border-color: transparent !important;
   }
   &.docketed {
     color: #666;
     background: #FF6;
     border-color: transparent !important;
   }
    &.notcomplied {
      background: #ff8100;
      border-color: transparent !important;
    }
    &.newissuance {
      background: #ff8100;
      border-color: transparent !important;
    }
  }

  div.status.flex1, div.status.ordered, div.status.current, div.status.open, div.status.active, div.status.NOVlate, div.status.inactive, div.status.falsecert, div.status.overdue, div.status.defect, div.status.failed, div.status.revoked, div.status.rejected, div.status.defaulted, div.status.fail, div.status.nycha_suspended {
    background: #d44937;
    border-color: #aa2b1a;
  }

  div.status.flex5,
  div.status.mailed,
  div.status.close, div.status.paid, div.status.paidinfull, div.status.issued, div.status.signed, div.status.complete, div.status.removed, div.status.cancel, div.status.dismissed, div.status.satisfactory, div.status.processed, div.status.pass, div.status.nycha_active {
    background: #86ca5d;
    border-color: #5cb327;
  }

  .expand-child fieldset {
    border: 1px solid #CCC;
    margin: 0 3px 9px 2px;
    padding: 6px 12px 10px;
    /* border-radius: 2px; */
  }
  .expand-child legend {
    color: #333333;
    text-transform: uppercase;
    font-size: 14px;
    border-bottom: none;
    width: auto;
    padding: 0 6px;
    margin: 0;
  }

  .expand-child fieldset div.half.left {
    float: left;
    width: 40%;
  }

  .expand-child fieldset div.title {
    width: 40%;
    font-weight: 700;
    float: left;
  }
  .expand-child fieldset div.detaile-title {
    width: 15%;
    font-weight: 700;
    float: left;
  }
  .expand-child div.data {
    margin: 0 10px 8px 0;
    line-height: 16px;
    display: table;
    white-space: normal;
  }

  .expand-child div.full {
    display: block;
    clear: both;
    margin-bottom: 6px;
    white-space: normal !important;
  }

  p.file, i.file {
    vertical-align: -15%;
    display: inline-table;
    padding: 0;
    margin: 0;
    width: 16px;
    height: 16px;
    //background: url(//images.empowerny.com/files.png) no-repeat;
    background-size: 256px;
  }
  .file.pdf {
    background-position: -64px;
  }

  .hazclass span {
    border: 1px solid #777;
    padding: 3px 7px;
    text-align: center;
  }

  td.order span {
    border: solid 1px #ccc !important;
  }

}

:not(.filter) > .status {
  text-rendering: optimizeLegibility;
  color: #FFF;
  text-align: center;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: .5pt;
  text-transform: uppercase;
  padding: 1px 2px;
  line-height: 14px;
  background: #b3b3b3;
  border: 1px dotted #999;
  border-radius: 12px;
  white-space: nowrap;
  max-width: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: none;
  opacity: 1;
}

.panel-heading {
  border-radius: 0px;
  min-height: 52px;
}
.bg-grey-cascade {
  border-color: #95A5A6 !important;
  background-image: none !important;
  background-color: #95A5A6 !important;
  color: #FFFFFF !important;
}

.violations_container {
  width: calc(100% - 272px);
  float: left;
  margin-left: 15px;
}

.status_history {
  margin-bottom: 10px;
}

.status_history span.date {
  text-align: center;
  display: block;
  padding: 2px 3px;
  background: #e4e4e4;
  letter-spacing: .5px;
  font-weight: 600;
  white-space: nowrap;
  border: 1px solid #ccc;
  border-bottom: none;
}

.status_history > div:not(:first-child):before {
  font-family: "FontAwesome";
  content: "\f0da";
  float: left;
  margin-left: -9px;
  margin-top: 12px;
  font-size: 16px;
}

.status_history > div {
  display: inline-table;
  min-width: 85px;
  margin-right: 11px;
  text-align: center;
  margin-bottom: 5px;

}
.status_history div {
  max-width: 118px;
}
.expand-child tr.head td, .expand-child tr.foot td, .expand-child tr.date td {
  display: table-cell;
  padding: 4px 8px 3px;
  background: #c4c4c4;
  letter-spacing: .5px;
  font-weight: 700;
  white-space: nowrap;
}

.expand-child .details td, .expand-child .dispHist td, .expand-child .vioHist td, .expand-child .statHist td, .expand-child .infrac td {
  border: 1px solid #CCC;
  display: table-cell;
  text-align: center;
}


@media (max-device-width: 767px ) {
  .pull-left{
    float:none !important;
  }
  .violations_container {
    width: 100%;
    float: none;
    margin-left: 1px;
  }

  .agencies-menu__agency__sub-menu{
    display:none;
  }
}
