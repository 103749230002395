#gg-content {  
  padding: 15px 25px 5px 25px;
  width: 100%;
}
.server-log {
  background-color: rgba(0, 0, 0, 0.05);
}
tr.server-log {
  background-color: rgba(0, 0, 0, 0.05);
}
#gg-siderbar, .mobile-sidebar {
  z-index: 999;
  width: 200px;
  height: calc(100% - 85px);
  background-color: #39bfe8;  
  padding: 15px 25px 25px 25px;
  position: fixed;
  top: 85px;  
  bottom: 0;
  overflow-y: auto;
  left: -200px;
}
#hide {
  color: white;
}
#gg-siderbar .btn-primary, .mobile-sidebar .btn-primary {
  padding: 3px 5px;
  width: 60px;
  float: right;
}
#gg-siderbar ul, .mobile-sidebar ul {
  list-style: none;
}
#gg-siderbar ul li, .mobile-sidebar ul li {
  margin-bottom: 10px;
}
#gg-siderbar ul li a, .mobile-sidebar ul li a {
  color: #fff;
  font-size: 14px;
}
#gg-siderbar ul li a.v-link-active, .mobile-sidebar ul li a.v-link-active {
  color: aqua;
}
#gg-wrap {
  margin: 0;
}

@media screen and (min-width: 992px) {

}
@media screen and (max-width: 768px) {
  #gg-siderbar, .mobile-sidebar {
    top: 105px;
  }
}
@media screen and (max-width: 992px) {
  #gg-siderbar, .mobile-sidebar { 
    left: -200px;    
  }
}

.tenants-table {
  tr.deactivated {
    background: rgba(68, 68, 68, 0.45);
    text-decoration: line-through;
    span {
      display: inline-block;
      padding: 4px;
    }        
  }
}