.chats-list dd {} .box-title {
    .online-status {
        i {
            top: 0px;
        }
        .group,
        .public {
            color: #39bfe8;
        }
    }
}
.navbar.navbar-static-top .online-status {
    i {
        font-size: 23px;
        top: 1px;
    }
    float:none !important;
    @include breakpoint(xs) {
        i {
            top: -2px;
        }
    }
}
.online-status {
    float: left;
    margin-right: 2px;
    i {
        font-size: 14px;
        top: 1px;
        position: relative;
    }
    .online {
        color: #27ff45;
    }
    .group,
    .public {
        color: floralwhite;
    }
    .offline {
        color: grey;
    }
    .away {
        color: #ffd738;
    }
    .do-not-distrub {
        color: #e63c27;
    }
}
.menu.online-status {
    i {
        margin-right: 7px;
    }
    a {
        color: #6f6d6d !important;
        font-size: 14px;
    }
}
