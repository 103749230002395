.w-less{
    &-800{
        .w-less-800-block{
            display: block;
            width: 100%;
        }
        .property-block__bg-blue--wide{
            background: transparent;
        }
    }
}
