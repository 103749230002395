.property-search-start{
  color: #41c8f2;
  font-size: 25px;
  padding-left: 15px;
  display: inline-block;
  float: left;
}

.property-search-start-note{
  color: #41c8f2;
  font-size: 14px;
  display: inline-block;
  margin-top: 10px;
}


.property-search {
  &__filters-container {
    height: calc(100% - 106px);
    @media (min-width: 768px) {
      height: calc(100% - 86px);
    }
    overflow: auto;
  }
  &__filters {
    background: $search-form-bg;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    width: 285px;
    min-height: 100%;

    .form-label, .form-label-between, .form-radio label {
      font-size: 16px;
      color: #fff;
      margin-bottom: 1px;
    }
    .form-label-between{
      line-height: 35px;
    }
    .form-group{
      margin-bottom: 9px;
    }
    .form-input {
      color: $color-light-gray-2;
    }
    .form-input,
    .btn-select,
    .multiselect.dropdown-toggle{
      height: 35px;
      background-image: none !important;
    }
    .form-radio.inline-block{
      margin-right: 1px;
      margin-top: 0px;
      margin-bottom: 3px;
      input{
        margin-top: 0px;
        background-color: transparent;;
      }
    }
    .input-group-addon{
      padding: 4px;
      font-size: 15px;
    }
    input.no-left-border:focus,
    input.no-left-border:active{
          border: 1px solid #abd7e9;
          border-left: none;
    }
    .no-left-border{
      border-left: none;
    }
    .with-right-icon{
      position: relative;
      span:after{
        content: "\f278";
        color: #55626b;
        position: absolute;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font: normal normal normal 16px/1 FontAwesome;
        float: right;
        right: 0px;
        top: 1px;
        line-height: 33px;
        padding: 0px;
        border-left: 1px solid #e0e1e3;
        min-width: 34px;
        text-align: center;
      }
    }
    .form-multiselect{
      position: relative;
      button{
        overflow: hidden;
      }
      .caret{
        display: block;
        border: none;
      }
      .caret:after{
        content: "\f0b0";
        display: block;
        color: #55626b;
        position: absolute;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font: normal normal normal 16px/1 FontAwesome;
        float: right;
        right: 0px;
        top: 1px;
        line-height: 33px;
        padding: 0px;
        border: none;
        border-left: 1px solid #e0e1e3;
        min-width: 34px;
        text-align: center;
        background: #fff;
      }
    }
  }
  &__results-container {
    height: calc(100% - 106px);
    @media (min-width: 768px) {
      height: calc(100% - 86px);
    }
    overflow: auto;
  }
  &__results {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    width: 100%;
    height: 100%;
    min-height: 100%;
    &-inner {
      height: 100%;
      position: relative;
    }
    &.-search-start {
      overflow: auto;
    }

    .scroll-block {
      position: absolute;
      top: 115px;
      bottom: 5px;
      left: 0;
      right: 0;
    }

    .back-icon {
      color: #41c8f2;
      font-size: 27px;
      padding: 0;
      background: none;
      border: none;
      margin-top: -2px;
    }

    .search-row {
      h3 {
        color: #41c8f2;
        padding: 0px 15px 0px 15px;
        margin-bottom: 0px;
      }
      .column-left {
        div {
          overflow: hidden;
          width: 100%;
          height: 38px;
          line-height: 38px;
          border: 1px solid #bdbdbd;
          color: #41c8f2;
          font-size: 12px;
          padding: 0 10px;
          white-space: nowrap;
          margin: 10px 0;
          text-overflow: ellipsis;
          padding-right: 35px;
          position: relative;

          .resent-search-icon {
            font-size: 18px;
            margin-top: 8px;
            position: absolute;
            right: 5px;
          }

          .delete {
            font-size: 18px;
            margin-top: 8px;
            position: absolute;
            right: 5px;
          }
        }
      }
      .column-right {
        div {
          overflow: hidden;
          width: 100%;
          height: 38px;
          line-height: 38px;
          border: 1px solid #bdbdbd;
          color: #41c8f2;
          font-size: 12px;
          padding: 0 10px;
          white-space: nowrap;
          margin: 10px 0;
          text-overflow: ellipsis;
          padding-right: 35px;
          position: relative;

          .resent-search-icon {
            font-size: 18px;
            margin-top: 8px;
            position: absolute;
            right: 5px;
          }

          .delete {
            font-size: 18px;
            margin-top: 8px;
            position: absolute;
            right: 5px;
          }
        }
      }
    }
    @media (min-width: 1200px) {
        width: calc(100% - 285px);
    }
  }
}

.paginator-count {
  line-height: 22px;
  margin-right: 15px;
}

.owner-search {
  input[type=text] {
    height: 35px;
    font-size: 15px;
    border: 1px solid #abd7e9;
    padding-left: 12px;
    width: 100%;
  }
  .dropdown-menu {
    width: 100%;
    padding-top: 0px;
    border: 0;
    overflow: hidden;
    & >li {
      & > a {
        padding: 3px 10px;
      }
    }
  }
}
#mapid_search_neighborhoods{
  width: 100%;
  min-height: 500px;
}
#mapid_search_neighborhoods{
  .leaflet-interactive{
    opacity: 0 !important;
    &:hover{
      opacity:.4 !important;
    }
    &.visible{
      &:hover{
        opacity:1 !important;
      }
      opacity:1 !important;
    }
  }
}
.toggle-search-form{
  cursor: pointer;
  display: none;
}
@media (min-width: 1200px){
  .neighborhood-modal  {
    .modal-dialog {
      width: 1024px;
    }
  }
  #mapid_search_neighborhoods{
    min-width: 384px;
  }
  .property{
    position: relative;
    min-height: 100%;
    height: calc(100vh - 86px);
    &-search{
      &__filters{
        position: absolute;
        height: calc(100vh - 86px);
        overflow: auto;
      }
      &__results{
        margin-left: 285px;
        height: calc(100vh - 86px);
        overflow: hidden;
        &-inner {
          // height: calc(100vh - 86px);
          // overflow: hidden;
          height: 100%;
          position: relative;
        }
      }
    }
  }
}
@media (max-width: 1199px){
  .property{
    &-search{
      &__filters{
        .form-label-between{
          padding-left: 0px;
        }
        display: none;
        width: 100%;
        padding-bottom: 10px;
        padding-top: 20px;
        &.expanded{
          position: absolute;
          display: block;
          z-index: 1001;
        }
      }
    }
    .toggle-search-form{
      display: block;
      &.fa{
        position: absolute;
        color:#fff;
        font-size: 20px;
        top: 10px;
        &.fa-times{
          right: 15px;
        }
        &.fa-search{
          top: 170px;
          left: 140px;
          background: $search-form-bg;
          padding: 10px;
          position: fixed;
          z-index: 1000;
          border: 1px solid #44505a;
          border-left: none;
        }
      }
      &.btn{
        border: 1px solid #fff;
        margin: 0 auto;
        min-width: 100px;
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px){
    .control-sidebar-open{
        .property-search {
            &__results{
                .btn-flat{
                    span{
                        display: none;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1199px){
    .property-search{
      &--wrapper{
        position: relative;
      }
    }
}
@media (min-width: 992px){
  .neighborhood-modal  {
    .modal-dialog {
      width: 900px;
      margin: 100px auto 0px;
    }
  }
  #mapid_search_neighborhoods{
    min-width: 332px;
  }
}
@media (min-width: 768px) and (max-width: 991px){
  .search_save_modal {
    margin: 84px auto 0px;
    .modal-dialog {
      width: 450px;
      margin: 30px 203px;
    }
  }
    .control-sidebar-open{
        .property-search {
            &__results{
                width: 100%;
            }
        }
    }
}
@media (max-width: 767px){
  .property{
    &-search{
      &__filters{
        width: 100%;
        overflow: scroll;
        position: fixed;
        height: 100%;
      }
    }
    .toggle-search-form{
      &.fa-search{
        left: 0px !important;
      }
    }
  }
}
@media (max-width: 440px){
  .search_save_modal{
    margin: 106px auto 0px;
  }
    .property-search {
      &__filters {
        .form-multiselect{
          .multiselect-container{
            >li{
              width: 100%;
            }
          }
        }
      }
    }
}
@media (max-width: 380px){
    .property-search {
        &__results{
            .btn-flat{
                span{
                    display: none;
                }
            }
        }
    }
}
.hide-right-sidebar{
  display: none !important;
}
.control-sidebar-open{
  .hide-right-sidebar{
    display: block !important;
  }
}

// Fix for top line of buttons in "property search results" block
.h-relative-layout__search-result-box[max-width~="380px"] {
  .btn-flat{
    span{
      display: none;
    }
  }
}
.h-relative-layout__search-result-box[max-width~="480px"] {
  .search-result-box__details {
    .search-result-box__details__column,
    .search-result-box__details__column-center {
      width: 100% !important;
      .col-xs-6 {
        width: 50% !important;
      }
    }
  }
}
