.leaflet-popup-content-wrapper {
  border-radius: 0;
}

.leaflet-popup-content {
  margin: 0px 6px;
  width:300px;
  a {
    color: #59c7f5;
    p {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
  span {
    color: #6b8091;
    font-size: 15px;
  }

  .street_image{
    height: 180px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin:  0 0 5px 0;
  }

  .neighborhood{
    display: block;
    font-size: 16px;
    margin:0px;
    color: #6b8091;
  }
  .address{
    font-size: 16px;
    display: block;
    font-weight: bolder;
    margin:0px;
  }
  .more_detile{
    display: block;
    background-color: #41c8f2;
    width: 100%;
    height: 30px;
    border-radius: 0;
    text-align: center;
    margin-bottom: 7px;
    margin-top: 6px;
    color: #fff;
    font-size: 16px;
    line-height: 30px;
  }
}
