.b-subletspy {
  margin-bottom: 20px;
  &__h2 {
    display: inline-block;
    font-size: 18px;
    font-weight: 200;
    color: #6b8091;
    margin-bottom: 15px;
  }
  &__chart-box {
    margin-bottom: 30px;
    max-width: 1000px;
  }
  hr {
    border-top: 1px solid #41c8f2;
  }
}
