.register-help-page{
  .content{
    //margin-top:25px;
  }
  .title{
    font-size:40px;
    color:#4EBFE7;
  }

  .list_number{
    margin-right: 12px;
    float: left;
    font-weight: bold;
    font-size: 31px;
    line-height: 50px;
    background-color: #4EBFE7;
    display: inline-block;
    content: counter(item);
    counter-increment: item;
    width: 46px;
    height: 46px;
    vertical-align: middle;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: white;
  }
  .list_title{
    font-size: 18px;
    margin: 11px 0 0 0px;
    color: #4ebfe7;
  }

  .list_body{
    margin: 0 0 0 58px;
    font-size: 12px;
    color: black;
    .body_row{
      margin:12px 0;
      a{
        color:black;
        text-decoration: underline;
      }
    }
  }
  .row_block{
    margin:  0 0 15px 0 ;
  }
  .help_button{
    float: left;
    margin: 12px 13% 10px 0;
    background-color: #55C8F1;
    color: white;
    box-shadow: 5px 5px 6px #4AA4C4;
    border: 2px solid #4EBFE7;
  }
  .add_property{
    font-size: 20px;
    min-width: 300px;
    padding: 3px 15px;
    text-align: center;
    white-space: nowrap;
  }
  .add_csv{
    font-size: 20px;
    padding: 3px 10px;
  }
  .block{
   /* min-height: 200px;*/
    background-color: white;
    margin:10px 0;
  }
  .property-dashboard-info-content {
    height: 230px;
    background-image: url("/img/property-info-table-img.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .input-address-search-content {
    height: 100px;
    .input-address-search {
      width: 260px;
      height: 68px;
      line-height: 68px;
      background-color: #49c8f4;
      margin: 20px auto 0 auto;
      text-align: center;
      position: relative;

      .icon-pp-search-top {
        background-image: url(../img/sprite.png);
        background-position: -153px -52px;
        width: 18px;
        height: 18px;
        position: absolute;
        z-index: 9;
        top: 23px;
        left: 45px;
      }
      input {
        font-size: 13px;
        background-color: #fff;
        outline: none;
        border: none;
        border-radius: 20px;
        height: 40px;
        line-height: 40px;
        text-indent: 42px;
        width: 80%;
        margin: auto;
        cursor: pointer;
      }
      input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color-light-gray-2;
        font-size: 13px;
      }
      input::-moz-placeholder {
        color: $color-light-gray-2;
        font-size: 13px;
      }
      input:-ms-input-placeholder {
        color: $color-light-gray-2;
        font-size: 13px;
      }
      input:-moz-placeholder {
        color: $color-light-gray-2;
        font-size: 13px;
      }
    }
  }
  .chats-content {
   height: 120px;
    .chat-content-img {
      width: 130px;
      height: 79px;
      background-image: url("/img/chats.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin: 25px auto 0 auto;
    }
  }

  .news-content {
   height: 120px;
    .news-content-img {
      width: 130px;
      height: 79px;
      background-image: url("/img/news.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin: 25px auto 0 auto;
    }
  }

  .fuzzy-content {
    height: 170px;

    .fuzzy-img {
      width: 96px;
      height: 152px;
      background-image: url("/img/fuzzy.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin: 10px auto 0 auto;
    }
  }

  @include breakpoint(sm, true) {
    .help-page-content {
      .col-md-6 {
        padding: 0;
      }
    }
    .list_body{
      margin: 10px 0 0 0px !important;
    }
    .content {
      padding: 0;
    }
    .title {
      color: #4EBFE7 !important;
      font-size: 27px;
    }
    .add_property {
      font-size: 12px;
    }
    .add_csv {
      font-size: 12px;
      padding: 3px 31px;
    }
    .block {
      padding-top: 1px;
    }
    .list_number {
      font-size: 25px;
      line-height: 40px;
      width: 37px;
      height: 37px;
    }
    .list_title {
      font-size: 16px;
      margin: 8px 0 0 0px;
    }
  }
  @include breakpoint(xs) {
    .add_property{
      min-width: 100%;
    }
  }

}
