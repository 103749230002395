@import '../vendor/bootstrap/variables';

$header-light-blue: #ccf0ff;
$header-dark-blue: #2f68c0;
$header-elements-border-radius: 25px;
$header-elements-height: 40px;
$header-slightly-darker: #169ED9;
$header-slightly-darker-right-panel: #39bfe8;
$header-base: #41c8f2; // the same as in theme variables

.dropdown.user-menu .dropdown-menu {
    width: 185px;
    .user-body {
        padding: 7px;
        border-bottom: 1px solid #f4f4f4;
        border-top: none;
    }
}
.main-header .navbar .nav > li > a:focus {
    background: #fff !important;
    color: $header-base !important;
}

.navbar.navbar-static-top {
    font-size: 12px;
    i {
        font-size: 14px;
    }
    .user-image {
        margin-top: -6px;
    }
}

.main-header, .main-header:hover {
    background-color: $header-base !important;
    .logo {
        font-family: $font-family-base;
        font-size: 24px;
        background-color: $header-base !important;
    }
}

.logo-lg {
    display: block !important;
    background: url(/img/logo-wide-new.png) no-repeat center center;
    background-size: cover;
    height: 40px;
    width: 211px;
    margin-top: 3px;
}

.logo-mini {
    display: none !important;
}
.view-changer{
    padding:0px;
    > a{
        display: inline-block;
        line-height: 39px;
        padding: 0 31px;
    }

}
@media (max-width: 1200px) {
    .logo-mini {
        display: block !important;
    }
    .logo-lg {
        display: none !important;
    }
    .sidebar-collapse.sidebar-mini .main-header .logo, .sidebar-open .main-header .logo, .main-header .logo {
        width: 85px;
        text-align: right; 
    }
    .sidebar-collapse.sidebar-mini .main-header .navbar, .sidebar-open .main-header .navbar {
        margin-left: 129px;
    }
}
.top-search {
    margin-top: 5px;
    margin-bottom: 5px;
    color: $header-dark-blue;

    .input-group {
        height: $header-elements-height;
        color: $header-dark-blue;
        // transition: width 0.3s ease-in-out;
        .form-control {
            border: none;
            background: #fff;
            height: 40px;
            border-radius: $header-elements-border-radius;
            font-size: 16px;
            color: $color-light-gray-2;
            width: 100%;
            padding-left: 50px;
        }
        .form-control::placeholder {
            color: $color-light-gray-2;
            font-size: 16px;
            font-weight: 400;
        }
        .input-group-btn {
            border: none;
            color: $header-dark-blue;
            background: $header-light-blue;
            border-radius: $header-elements-border-radius 0px 0px $header-elements-border-radius;
            .btn,
            .btn:hover,
            .btn:active {
                background: transparent;
                border: none;
                box-shadow: none;
                .fa {
                    font-size: 20px;
                    color: $header-dark-blue;
                }
            }
        }
        .input-group-btn:hover,
        .input-group-btn:active {
            border: none;
        }
    }
}

.nav.navbar-nav {
    @include breakpoint(xs) {
        >li.user-menu {
            .user-image {
                margin-top: -3px;
            }
        }
    }
    @include breakpoint(sm) {
        >li {
            &.control-sidebar-toggler a {
                padding-top: 30px;
            }
        }
    }
    @media (min-width: 768px){
        >li {
            height: 86px;
            margin-top: -20px;
            vertical-align: middle;
            >a {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                padding-top: 31px;
            }
            .user-image {
                width: 30px;
                height: 30px;
                margin-top: -5px;
                margin-right: 1px;
                margin-left: -6px;
            }
        }
    }
    @media (min-width: 992px) {
        >li {
            .user-image {
                width: 63px;
                height: 63px;
                margin-top: -2px;
                margin-right: -5px;
                margin-left: 5px;
            }
        }
        .navbar-custom-menu {
            &__number {
                display: inline-block;
                height: 25px;
                width: 25px;
                border-radius: 15px;
                line-height: 25px;
                text-align: center;
                background-color: #33b3da;
                color: #d0f4ff;
            }
            &__label {
                padding-left: 10px;
                display: inline-block;
                font-size: 14px;
            }
        }
        li.user-menu {
            font-size: 16px;
            font-weight: 400;
            > a {
                padding: 13px 20px 12px 10px;
            }
            &:hover {
                &.open {
                    > a {
                        color: $header-dark-blue;
                    }
                }
            }
            &.open,
            &:hover {
                > a {
                    color: $header-dark-blue;
                }
            }
            &.open {
                > a {
                    color: $header-dark-blue !important;
                }
            }
            @media (max-width:1290px) {
                > a {
                    padding: 13px 10px 12px 10px;
                    > span {
                        display: none;
                    }
                    > img {
                        margin-right: 0px !important;
                    }
                }
            }
        }
        li.control-sidebar-toggler {
            a{
                padding-top: 36px;
            }
            &:hover,
            &:focus {
                background: $header-light-blue;
                > a {
                    background: transparent !important;
                    color: $header-dark-blue;
                    i {
                        color: $header-dark-blue;
                    }
                }
            }
            > a {
                &:hover,
                &:focus,
                &:active {
                    background: $header-light-blue;
                }
            }
        }
    }
}
.navbar {
    .view-changer {
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: $color-blue-default;
        border-radius: $header-elements-border-radius;
        float: left;
        line-height: 24px;
        border: 1px solid #fff;
        > a {
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            i {
                margin-left: 15px;
            }
        }
        &.open {
            ul.dropdown-menu {
                width: 100%;
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        .top-search {
            .input-group {
                width: 350px;
            }
        }
        .control-sidebar-toggler{
            a:focus{
                background: #fff !important;
                color: $header-base !important;
            }
        }
    }
    @media (max-width: 1450px) {
        .top-search {
            .input-group {
                width: 230px;
            }
        }
        .view-changer > a i{
            margin-left: 0px;
        }
    }
    @media (min-width: 1200px) and (max-width: 1249px) {
        .top-search {
            .input-group {
                width: 130px;
            }
        }
    }
    @media (min-width: 1250px) and (max-width: 1310px) {
        .top-search {
            .input-group {
                width: 150px;
            }
        }
    }
    @media (min-width: 992px) and (max-width: 1040px) {
        .top-search {
            .input-group {
                width: 185px;
            }
        }
    }
    @media (max-width: 991px) {
        margin-left: 0px !important;
            .mobile-view{
                background-color: $header-slightly-darker;
                padding: 0 15px;
                a.logo{
                    float: left;
                    background-color: $header-slightly-darker !important;
                    span{
                    line-height: 40px;
                    }
                }
                a.logo, .col-xs-9{
                    // width: auto !important;
                }
                form{
                    display: inline-block;
                    float: right;
                    border-top:none;
                    border-bottom:none;
                    box-shadow: none;
                    .input-group{
                        background: $header-base !important;
                        border-radius: 7px;
                        width:300px;
                        #search-btn-mobile{
                            > span{
                                display:none;
                            }
                        }
                        .btn.btn-flat{
                            font-size:15px;
                        }
                        input{
                            padding-left:10px;
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            padding-left: 5px;
                        }
                        &-btn{
                            background: #fff;
                        }
                        i{
                            top: 5px;
                            left: 0px;
                            position: relative;
                        }

                    }
                }
                .view-changer{
                    float: right;
                    margin-top:15px;
                    height: 40px;
                    margin-left: 10px;
                    background: $header-base;
                    > a{
                        color: #fff;
                        font-size:15px;
                        font-weight: 400px;
                    }
                    .dropdown-menu{
                        left: auto;
                        right: 0px;
                        a{
                            color: #777 !important;
                            padding: 5px 20px;
                        }
                        a:hover, a:focus, a:active{
                            color: #222 !important;
                            background-color: #e1e3e9 !important;
                        }
                        .divider{
                            background-color: #eee !important;
                        }
                    }
                }
            }
            .sidebar-toggle{
                padding: 10px 15px !important;
            }
            .sidebar-toggle:before{
                font-size:19px !important;
                line-height: 30px;
            }
            .navbar-custom-menu{
                .fa-bars{
                    font-size: 19px !important;
                }
                .dropdown.user-menu{
                    > a{
                        padding-bottom: 14px !important;
                    }
                }
                li.control-sidebar-toggler{
                    > a{
                        &:hover,
                        &:focus,
                        &:active{
                            background: transparent !important;
                            color: #fff !important;
                        }
                    }
                    &:hover{
                        > a{
                            &:hover{
                                background: rgba(0, 0, 0, 0.1) !important;
                            }
                        }
                    }

                }
                &__label{
                    i{
                        display: inline-block !important;
                    }
                }
            }
            .control-sidebar-open {
                li.control-sidebar-toggler{
                    > a{
                        &:hover,
                        &:focus,
                        &:active{
                            background: transparent !important;
                            color: $header-base !important;
                        }
                    }
                    &:hover{
                        > a{
                            &:hover{
                                background: $header-light-blue !important;
                            }
                        }
                    }
                }
            }

        .top-search {
            .input-group {
                width: 120px;
            }
        }
        .container-for-large-screen{
            display: none;
        }
    }















    @media (max-width: 767px) {
        margin-left: 0px !important;
        .mobile-view{
            background-color: $header-slightly-darker;
            padding: 0 3px;
            a.logo{
                float: left;
                background-color: $header-slightly-darker !important;
                span{
                    line-height: 40px;
                }
            }
            a.logo, .col-xs-9{
                // width: auto !important;
                height: 56px;
            }
            .col-xs-9 {
                margin-top: -5px;
                // margin-left: 80px;
            }
            .logo-xs {
                background-image: url('/img/PPxyz_Wide_600x150_logo-A-3.png') !important;
            }
            form{
                display: inline-block;
                float: right;
                border-top:none;
                border-bottom:none;
                box-shadow: none;
                .input-group-btn {
                    background: #41c8f2 !important;
                }
                .input-group{
                    background: #169ed9 !important;
                    border-radius: 25px;
                    width:102px;
                    border: 1px solid #fff;

                    .header-search {
                        margin-left: -12px;
                    }

                    .twitter-typeahead {
                        width: 71px;

                        .tt-input::-webkit-input-placeholder {
                            color: #fff;
                            font-size: 14px;
                        }
                        .tt-input:-moz-placeholder {
                            color: #fff;
                            font-size: 14px;

                        }
                        .tt-input::-moz-placeholder {
                            color: #fff;
                            font-size: 14px;

                        }
                        :-ms-input-placeholder {
                            color: #fff;
                            font-size: 14px;

                        }

                        .tt-hint {
                            height: 38px;
                            background-color: #41c8f2 !important;
                        }

                    }
                    .tt-input:focus {
                        width: 255px !important;
                    }
                    #search-btn-mobile{
                        > span{
                            display:none;
                        }
                    }
                    .btn.btn-flat{
                        font-size:15px;
                    }
                    input{
                        padding-left:10px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        padding-left: 5px;
                    }
                    &-btn{
                        background: #fff;
                    }
                    i{
                        top: 5px;
                        left: -6px;
                        position: relative;
                    }

                }
            }
            .view-changer{
                float: right;
                margin-top:15px;
                height: 40px;
                margin-left: 10px;
                background: $header-base;
                > a{
                    color: #fff;
                    font-size: 15px;
                    font-weight: 400;
                }
                .dropdown-menu{
                    left: auto;
                    right: 0px;
                    a{
                        color: #777 !important;
                        padding-top: 8px;
                        padding: 8px 16px;
                    }
                    a:hover, a:focus, a:active{
                        color: #222 !important;
                        background-color: #e1e3e9 !important;
                    }
                    .divider{
                        background-color: #eee !important;
                    }
                }
            }
        }
        .sidebar-toggle{
            padding: 10px 15px !important;
        }
        .sidebar-toggle:before{
            font-size:19px !important;
            line-height: 30px;
        }
        .navbar-custom-menu{
            .fa-bars{
                font-size: 19px !important;
            }
            .dropdown.user-menu{
                > a{
                    padding-bottom: 14px !important;
                }
            }
            li.control-sidebar-toggler{
                > a{
                    &:hover,
                    &:focus,
                    &:active{
                        background: transparent !important;
                        color: #fff !important;
                    }
                }
                &:hover{
                    > a{
                        &:hover{
                            background: rgba(0, 0, 0, 0.1) !important;
                        }
                    }
                }

            }
            &__label{
                i{
                    display: inline-block !important;
                }
            }
        }
        .control-sidebar-open {
            li.control-sidebar-toggler{
                > a{
                    &:hover,
                    &:focus,
                    &:active{
                        background: transparent !important;
                        color: $header-base !important;
                    }
                }
                &:hover{
                    > a{
                        &:hover{
                            background: $header-light-blue !important;
                        }
                    }
                }
            }
        }

        .top-search {
            .input-group {
                width: 120px;
            }
        }
        .container-for-large-screen{
            display: none;
        }
    }


















    @media (min-width: 768px) and (max-width: 991px) {
        .logo-xs {
            background-image: url('/img/PPxyz_Wide_600x150_logo-A-2.png') !important;
        }
        .mobile-view{
            display: inline-block !important;
            background-color: $header-base !important;
            min-width: 610px;
            a.logo, .col-xs-9{
                padding-top:0px !important;
                background-color: $header-base !important;
            }
            .view-changer{
                margin-top: 5px;
            }
            form{
                margin-top: 5px;
                padding-left: 0px;
                .input-group{
                    width:228px;
                    input{
                        font-size:15px !important;
                    }
                }
            }
        }

        .navbar-nav {
            li{
                a{
                    line-height: 33px;
                    min-height: 54px;
                }
                &.user-menu{
                    a{
                        padding-top: 33px;
                    }
                    .user-body{
                        a{
                            padding-top: 0px;
                        }
                    }
                }
            }
        }
    }
}
.login-button{
    display: block;
    border: 1px solid white;
    margin-right: 32px;
    padding: 5px 29px;
    font-size: 18px;
    border-radius: 30px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    outline: none;
    &:hover{
        cursor: pointer;
        color:white;
        background-color: #119ec9
    }
    &:active{
        background-color: #119ec9;
        color: white !important;
    }
    &:focus{
        background-color: #119ec9;
        color: white !important;
    }
}
.login-button-xs{
    float: right;
    display: block;
    border: 1px solid white;
    vertical-align: center;
    padding: 4px 20px;
    font-size: 16px;
    border-radius: 24px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    margin-top: 9px;
    margin-right: 18px;
}
.mobile-view{
    .logo{
        .logo-xs {
            background-image: url('/img/PPxyz_Wide_600x150_logo-A-3.png');
            background-repeat: no-repeat;
            background-size: cover;
            width: 76px;
            height: 50px;
            display: inline-block;
            background-size: 75px 47px;
            margin-top: 5px;
        }
    }
}

@media (min-width: 1201px) {
    nav.navbar.navbar-static-top{
        margin-left: 265px !important;
    }
    .main-header {
        .logo {
            width: 265px !important;
            padding: 0 42px !important;
        }
    }
}
@media (min-width: 1600px) {
    nav.navbar.navbar-static-top{
        margin-left: 0px !important;
        .navbar-custom-menu{
            background: $header-slightly-darker-right-panel;
            margin-top: -20px;
            width: 37%;
            float: right;
            .navbar-nav{
                float: right;
                margin-top: 20px;
            }
        }
        .container-for-large-screen{
            display: inline-block;
            height: 66px;
            position: absolute;
            right: 37%;
            padding-right: 48px;
            .top-search{
                padding-left: 42px;
                .input-group{
                    width: 380px;
                }
            }
        }
    }
    .main-header {
        .logo {
            z-index: 1001;
        }
    }

}
@media (min-width: 1700px) {
    nav.navbar.navbar-static-top{
        .container-for-large-screen{
            .top-search{
                padding-left: 42px;
                .input-group{
                    width: 440px;
                }
            }
        }
    }
}

@media (max-width: 767px){
    .main-header {
        max-height: none;
        padding-top: 0;
    }
    .navbar-custom-menu{
        >ul.navbar-nav{
            >li{
                height: 50px;
                >a{
                    max-height: 50px;
                }
            }
        }
    }
    .mobile-view:not(.active){
        form.top-search{
            .input-group{
                >div{
                    &.header-search {
                        &>input {
                            color: #fff;
                            background-color: #41c8f2 !important;
                            &::-webkit-input-placeholder {
                                color: #fff;
                                font-size: 14px;
                            }
                            &:-moz-placeholder {
                                color: #fff;
                                font-size: 14px;
                            }
                            &::-moz-placeholder {
                                color: #fff;
                                font-size: 14px;
                            }
                            &:-ms-input-placeholder {
                                color: #fff;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) and (min-width: 451px){
    .logo-xs {
        background-image: url('/img/PPxyz_Wide_600x150_logo-A-3.png') !important;
    }
    .navbar {
        .mobile-view {
            .col-xs-9 {
                margin-top: -7px;
                // margin-left: 77px;
            }
            .view-changer {
                width: 85px;

                a {
                    padding: 0 10px;
                }
            }
            form {
                .input-group {
                    width: 160px;

                    .twitter-typeahead {
                        width: 130px;
                    }
                }
            }
        }
    }
}

@media (max-width: 450px) {
  .logo-xs {
    background-image: url('/img/PPxyz_Wide_600x150_logo-A-3.png') !important;
  }
  .navbar {
    .mobile-view {
      .col-xs-9 {
        // margin-top: -62px;
        // margin-left: 88px;
      }
      .view-changer {
        width: 85px;

        a {
          padding: 0 10px;
        }
      }
      form {
        .input-group {
          width: 160px;

          .twitter-typeahead {
            width: 130px;
          }
        }
      }
    }
  }
}
@media (max-width: 340px) {
    .mobile-view{
        .logo{
            small{
                display: none;
            }
        }
    }
}
.main-header {
    height: 86px;
}

@media (max-width: 320px){
  .navbar {
    .mobile-view {
      .col-xs-9 {
        // margin-left: 80px;
      }
      .view-changer {
        width: 100px;

        a {
          padding: 0 10px;
        }
      }
      form {
        .input-group {
          width: 110px;

          .twitter-typeahead {
            width: 79px;
          }
        }
      }
    }
  }
    .top-search{
       .input-group{
           .form-control .tt-input{
               width:90px;
           }

       }
    }
}

