.lease-endings {
    width: 100%;
    overflow-x: scroll;
    &__table{
        font-size: $font-size-base;
        &__title{
            font-weight: lighter;
            vertical-align: bottom;
            &--first{
                font-size: $font-size-big;
            }
        }
        &__column{
            &__text{
                &__marked{
                    display: inline-block;
                    width: 100%;
                    text-transform: uppercase;
                    padding: 2px;
                    &--green{
                        background: #00ac4a;
                    }
                    &--yellow{
                        background: yellow;
                    }
                }
            }
            
        }
        tr > td{
            &:nth-child(5){
                text-align: center;
            }
        }
    }
}