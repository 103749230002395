@import "./node_modules/compass-mixins/lib/compass/css3";
$white: white;
$highlightColorActive: #525f6a;
$inactiveColor: #bbe8fb;
$blue: rgb(69, 113, 236);


@mixin square($dim) {
  width: $dim;
  height: $dim;
}

.unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.centered {
  width: 300px;
  margin: auto;
}

.text-center {
  text-align: center;
}


// The important CSS
// ------------------------


.control-inline label {
  display: inline;
}

// Had .csstransforms here, but caused weird compass errors
:not(#foo) .styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    @include square(20px);
    background: $white;
  }

  &:focus + label:before,
  &:hover + label:before {
    background: $inactiveColor;
    // box-shadow: 0 0 0 6px rgba(0,0,0,0.12);
  }

  &:checked + label:before {
    background: $highlightColorActive;
  }
  
  // Disabled state
  &.disabled + label,
  &[disabled] + label {
    color: #b8b8b8;
  }
  &.disabled + label:before,
  &[disabled] + label:before {
    box-shadow: none;
    background: #ddd;
  }



  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 9px;
    background: $white;
    @include square(2px);
    box-shadow: 
      2px 0 0 $white,
      4px 0 0 $white,
      4px -2px 0 $white,
      4px -4px 0 $white,
      4px -6px 0 $white;
    @include rotate(45deg);
  }
}

.roles {
    label:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 9px;
        background: $white;
        @include square(2px);
        box-shadow: 
          2px 0 0 $white,
          4px 0 0 $white,
          4px -2px 0 $white,
          4px -4px 0 $white,
          4px -6px 0 $white;
        @include rotate(45deg);
      }

      :not(#foo) .styled-checkbox {
        & + label:before {
            background: $inactiveColor;
        }
          &:checked + label:before {
            background: $highlightColorActive;
          }
      }      
}


input[type="radio"] {
    background-color: $highlightColorActive;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin-right: 15px;
    position: relative;
    width: 20px;
    -webkit-appearance: none;

    &:after {
        border-radius: 25px;
        content: '';
        display: block;
        height: 18px;
        left: 1px;
        position: relative;
        top: 1px;
        width: 18px;
        background-color: white;
    }

    &:hover:after {
      background-color: $highlightColorActive;
      :after {
        background-color: white;
      }
    }

    &:checked:after  {
      background-color: $highlightColorActive;
      :after {
        background-color: $highlightColorActive;
        box-shadow: inset 0 0 0 1px hsla(0,0%,0%,.4),
                inset 0 2px 2px hsla(0,0%,100%,.4),
                0 1px 1px hsla(0,0%,100%,.8),
                0 0 2px 2px hsla(0,70%,70%,.4);
              }        
    }

    &:focus {
      outline: none;
    }
}