.upcoming-event {
  &__table{
    border:0px;

    &__message{

    }
    &__time{
      float:right;
    }
    tr{
      td{
        border:0px !important;

      }
    }
    &__title{
      font-size: $font-size-base;
      font-weight: lighter;
      vertical-align: bottom;
      &--first{
        font-size: $font-size-big;
      }
    }
    &__column{
      &--first{
        padding-top: 0px;
        padding-bottom: 0px;
        vertical-align: center;
      }
      &__badge{
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 21px;
        vertical-align: middle;
        &--red{
          background: red;
        }
        &--yellow{
          background: #f39c12;
        }
        &--green{
          background: green;
        }
      }
    }
  }
}
@media (min-width:1400px){
  .alerts-messages__table__column{
    &__date{
      width: 80px;
      float: left;
      display: inline-block;
    }
    &__time{
      width: calc(100% - 80px);
      float: left;
      display: inline-block;
    }
  }
}