#chat-window-container-preload {
    height: 300px;
    font-size: 41px;
    height: 300px;
    text-align: center;
    padding-top: 50px;
}
#chat-window-container {
    background-color:#eef3f6;
    position: absolute;
    width: 100%;
    height: calc(100% - 2px);
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    .select2-container {
        width:100% !important;
    }

    .chat-sidebar {
        margin-left: -15px;
    }

    #user-info-modal {
        .card {
            margin-top: 20px;
            margin-right: 0;
            width: auto;
            float: none;
        }
    }
}
@media (max-width: 767px) {
    #chat-window-container {
        >.flex-col:nth-child(2){
            height: 100%;
        }
    }
}
#add-users-container {
    .select2-container {
        z-index:999999;
    }
}

.sweet-alert {
    z-index:999999;
}
.chat-sidebar {
    background-color:#39bfe8;
    .select2-container {
        width:100% !important;
    }
}
.sidebar.left {
    .modal-title {
        color:black;
    }
    .select2-container {
        width:100% !important;
    }
}

.select-avatar img {
    width: 30px;
    height: 30px;
}
.select-name {
    margin-left:10px;
    font-size:20px;
}
.chats-list dt {
    font-size:14px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom:6px;
}
.chats-list dd a {
    font-size:14px;
    color: #ffffff;
    font-weight: 300;
    margin-left: 3px;
}
.chats-list dd {
    margin-left: 2px;
    margin-bottom:6px;
}
.chats-list {
    padding: 15px 0px 0px 15px;
    .dropdown-menu {
        right: -81px;
        background-color:#525f6a;
        color: #ffffff;
        border-color:#525f6a;
        a {
            color: #ffffff;
        }
    }
}
#new-chat {
    background-color:#525f6a;
    color: #ffffff;
    margin-right: 20px;
    text-transform: uppercase;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 15px;
    padding: 3px 10px 1px 10px;
}
#new-chat:hover,
#new-chat:active,
#new-chat:focus {
    text-decoration:none;
}
.chat-window-box {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 35px;

    .select2-container {
        width:100% !important;
    }
    .badge {
        background-color: #39bfe8;
        padding: 5px 7px 2px 7px;
    }
}

@media (min-width: $screen-md-min) {
    .chat-window-box {
        left: 0px;
        right: 25px;
        bottom: 15px;
    }
}

@include breakpoint(xs) {
    .chat-window-box {
        left: -1px;
        right: -1px;
        bottom: 2px;
        top: -1px;
    }
}

@include breakpoint(sm) {
    .chat-window-box {
        left: 25px;
        right: 25px;
        bottom: 15px;
        top: 25px;
    }
}



.chat-window-box .box {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #869bac !important;
    height: 100%;
    margin-bottom: 0;

    .direct-chat-text:before, .direct-chat-text:after {
        display:none;
    }
    .direct-chat-name {
        font-size:10px;
    }
    .direct-chat-msg {
        .direct-chat-text {
            background: #eef3f6;
            border-color: #eef3f6;
            color: #000000;
            font-size: 12px;
            line-height: 1.5;
        }
    }
    .box-header {

        border-bottom: 1px solid #39bfe8;
        .btn-box-tool {
            padding:0px;
            i {
                color:#39bfe8;
            }
        }
        h3 {
            font-weight: 300;
            font-size:14px;
            color:#39bfe8;
        }
    }

    .box-body {
        height: calc(100% - 86px);

        .direct-chat-messages {
            height: 100%;
        }
    }

    .box-footer {
        border-bottom: 1px solid #869bac;
    }
}


// Styles for chat sidebar
.sidebar.left {
    left: -275px;
    z-index: 999999;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 270px;
    background: #448AFF;
}

.sidebar.right {
    top: 0;
    right: 0;
    bottom: 0;
    width: 270px;
    background: #448AFF;
}

.sidebar.bottom {
    left: 0;
    right: 0;
    bottom: 0;
    height: 270px;
    background: #03A9F4;
}

.sidebar.top {
    left: 0;
    right: 0;
    top: 0;
    height: 270px;
    background: #03A9F4;
}

.sidebars > .sidebar {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.64);
    position: fixed;
    color: white;
    padding: 30px;
    font-size: 2em;
}
