.emojione {
  /* Emoji Sizing */
  font-size: inherit;
  height: 2.8ex;
  width: 2.9ex;
  min-height: 18px;
  min-width: 18px;

  /* Inline alignment adjust the margins  */
  display: inline-block;
  margin: -.2ex .15em .2ex;
  line-height: normal;
  vertical-align: middle;
}

img.emojione {
  /* prevent img stretch */
  width: auto;
}
