.comps-section{
    padding:0;
    /*profile-header*/
    .profile__header{
        padding: 25px;
        height: 315px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &.profile__header--vendor{
            background-color: $vendor-color;
        }
        /*alert-bar*/
        .alert{
            &.alert-danger{
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                padding: 9px;
                @media screen and (max-width:767px){
                    padding:6px;
                }
                &.alert-dismissible {
                    .close{
                        top: 0;
                        right: 0;
                    }
                }
                @media screen and (max-width:520px){
                    position: relative;
                    z-index: 6;
                }
            }
        }
        /*alert-bar-ends*/
        /*profile-image*/
        .profile__image__datails{
            position:absolute;
            bottom:15px;
            z-index:4;
            img{
                &.profile-pic{
                    z-index: 21;
                    margin-right: 10px;
                    padding: 0;
                    max-width: 150px;
                    height: 145px;
                    border-radius: 8px;
                    border: 3px solid white;
                    float: left;
                    @media screen and (max-width:991px){
                        float: none;
                        margin-right: 0px;
                    }
                }
            }
            /*profile-details*/
            .profile__details{
                text-shadow:2px 2px 2px $black;
                float: left;
                margin-top: 24px;
                p{
                    color:$white;
                    margin-bottom: 3px;
                    font-weight: 300;
                    line-height:1.12;
                    &.profile__details--oraginsation{
                        font-weight: 500;
                        font-size: 22px;
                    }
                }
                @media screen and (max-width:991px){
                    float:none;
                    margin-top: 15px;
                }
                @media screen and (max-width:520px){
                    margin-top: 0px;
                    margin-bottom: 12px;
                }
            }
            /*profile-details*/
            @media screen and (max-width:991px){
                left: 50%;
                transform: translate(-50%, 0);
            }
            @media screen and (max-width:520px){
                    bottom: 43px;
            }
        }
        /*profile-image-details-ends*/
        /*profile-connect*/
        .profile__connect{
            position:absolute;
            bottom:20px;
            right :22px;
            text-align: left;
            padding-left: 0;
            .connect-buttons{
                float:right;
                .profile__connect--btn-blue{
                    background-color: $blue-btn;
                    color:$white;
                    text-transform: uppercase;
                    padding: 5px 20px;
                    font-weight: 500;
                    border-radius: 0;
                    box-shadow: none;
                    min-width: 105px;
                    @media screen and (max-width:991px){
                        display: none;
                        &:first-child{
                            display: block;
                            float: left;
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
        /*profile-join-nav-mobile*/
        .bootstrap_btn_mobile {
            display: none;
            z-index: 7;
        }
        @media screen and (max-width:991px){
            .bootstrap_btn_mobile {
                display: block;
                max-width: 150px;
                text-align: center;
                position: absolute;
                right: 0px;
                .navbar-toggle{
                    margin: 0;
                    background: $blue-btn;
                    border-radius: 0;
                    padding: 5px;
                    display: block;
                    .fa-bars{
                        font-size: 19px;
                        color: $white;
                    }
                    #close-sign{
                        display: none;
                        position: absolute;
                        top: -1px;
                        right: -1px;
                        left: -1px;
                        bottom: -1px;
                        background-color: $blue-btn;
                        font-size: 19px;
                        line-height: 30px;
                        color:$white;
                    }
                    .show{
                        display: block;
                    }
                }
                a{
                    display: none;
                    text-transform: uppercase;
                    background-color: $blue-btn;
                    width: 100%;
                    padding: 10px 0;
                    color:$white;
                    font-weight: 500;
                    float: left;
                    padding:7px 15px 8px 15px;
                    margin-bottom:1px;
                    margin-top: -45%;
                    &:hover{
                        text-decoration: none;
                    }

                    &:last-child{
                        margin-top: -68%;
                    }
                }
            }
        }
        /*profile-join-nav-mobile ends*/
        /*profile-connect-ends*/
    }
    /*profile-header-ends*/
    /*.profile-timeline*/
    .profile__timeline{
        color:$black;
        position:relative;
        width:100%;
        float:left;
        /*contact-info*/
        .timeline__contact--outer{
            padding:0px;
            .tab__links--skyblue{
                &:hover{
                    a{
                        border-left:none;
                        color:$black;
                        background:$white;
                        border-left: 3px solid #11aff0;
                    }
                }
                &.active{
                    a{
                        background:$white;
                        border-left:none;
                        color:$black;
                    }
                }
                a{
                    font-size: 14px;
                    font-weight: 300;
                    padding: 12px 5px 12px 10px;
                    display: block;
                }
                .active{
                    font-weight: 500;
                    background-color: white;
                }
                .fa{
                    display:none;
                    @media screen and (max-width: 767px){
                        display: inline-block;
                        float: right;
                        line-height: 23px;
                    }
                }
            }
            h4{
                margin-bottom: 0;
            }
            ul{
                background-color: $light-sky-blue-bg;
                li{
                    font-size:14px;
                    a{
                        padding: 12px 5px 12px 10px;
                        color:$black;
                        text-transform: capitalize;
                        &:hover{
                            color:$light-sky-blue;
                            .pull-right-container{
                                left: 180px!important;
                            }
                        }
                        .fa-angle-left{
                            float:right;
                        }
                        .pull-right-container{
                            display:block !important;
                        }
                    }
                    ul{
                        &.treeview-submenu{
                            background-color: $list-dark-blue;
                            list-style: none;
                            padding: 0px;
                            display:none;
                            li{
                                a{
                                    border-left: 3px solid transparent;
                                    padding: 12px 10px;
                                    display: block;
                                    &:hover{
                                        border-color: $sky-blue-text;
                                    }
                                }
                            }
                        }
                    }
                    &.arrow-down{
                        a{
                            border-left:none;
                            background:$list-dark-blue;
                            color:$white;
                            .fa-angle-left{
                                transform: rotate(-88deg);
                                margin-top: -7px;
                                margin-right: 7px;
                            }
                        }
                    }
                }
                &:nth-child(1){
                    li{
                        a{
                            border-left:none;
                        }
                        &:hover{
                            a{
                                background:$light-sky-blue-bg;
                                border-left:none;
                                color:$black;
                            }
                        }
                        &.active{
                            a{
                                background:none;
                                border-left:none;
                                color:$black;
                            }
                        }
                    }
                }
            }
            .timeline__contact--address{
                .fa-map-marker{
                    font-size: 24px;
                    margin-right: 5px;
                    float: left;
                }
            }
            .timeline__contact--call{
                .fa-phone{
                    font-size: 24px;
                    margin-right: 5px;
                    float: left;
                }
            }
            .timeline__contact--mail{
                text-transform: uppercase;
                .fa-envelope-o{
                    font-size: 24px;
                    margin-right: 5px;
                    float: left;
                }
            }
            h3{
                margin: 5px 10px;
                color:$sky-blue-text;
            }
        }
        /*contact-info-ends*/
        /*timeline*/
        .profile__timeline--block{
            .profile__timeline__about{
                float: left;
                font-size: 14px;
                .timeline__about--description{
                    padding:10px 0px;
                    p{
                        margin-bottom: 0;
                    }
                }
                /*video*/
                .profile__timeline__welcome--video{
                    margin-top: 20px;
                    position: relative;
                    padding-bottom: 56.25%;
                    padding-top: 25px;
                    height: 0;
                    h3{
                        margin-top: 0px;
                    }
                    iframe{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: none;
                    }
                }
                /*video-ends*/
                .about__post{
                    /*info-added*/
                    .comp__classification{
                        padding: 0;
                        table{
                            tr{
                                td{
                                    padding: 4px;
                                    &:first-child{
                                        font-weight: bold;
                                        text-align: right;
                                    }
                                    &:last-child{
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                    .comp__info{
                        padding: 0;
                        table{
                            tr{
                                td{
                                    padding: 4px;
                                    &:first-child{
                                        font-weight: bold;
                                        text-align: right;
                                    }
                                    &:last-child{
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                    /*info-added-ends*/

                    /*                    .about__post__facts{
                                            padding-left: 0;
                                        }
                                        .about__post__endorsement{
                                            padding-right: 0;
                                        }*/
                }
            }
            .profile__timeline__performance{
                .dataTables_wrapper{
                    overflow: hidden;
                }
                table.dataTable{
                    thead{
                        .sorting_asc:after{
                            content: "\e150"
                        }
                        .sorting_desc:after {
                            content: "\e150";
                        }
                    }
                }
                .dataTables_paginate{
                    .paginate_button{
                        padding:0px;
                        &:hover{
                            background: none;
                            border: none;
                            background-color:none;
                            border: 1px solid transparent;
                        }
                    }
                    ul{
                        padding: 0px;
                        .disabled{
                            a{
                                cursor:pointer;
                                &:hover{
                                    cursor:pointer;
                                }
                                &:focus{
                                    cursor:pointer;
                                }
                            }
                        }
                        a{
                            cursor:pointer;
                            &:hover{
                                cursor:pointer;
                            }
                            &:focus{
                                cursor:pointer;
                            }
                        }
                    }
                }
                .performance__heading--red{
                    color: $red-text ;
                    margin-bottom: 5px;
                }
                .performance__heading--brown{
                    color: $brown-text ;
                    margin-top: 0;
                    margin-bottom: 25px;
                }
                .performance__heading--black{
                    margin-top: 25px;
                    margin-bottom: 25px;
                }
                .performance__heading--blue{
                    color:$dark-blue;
                }
                /*tabs-custom*/
                .nav-tabs-custom {
                    font-size: 11px;
                    box-shadow:none;
                    .nav-tabs{
                        li {
                            margin-right: 0px;
                            &.active{
                                border-top: none;
                                a{
                                    border-top-color: $tab-border;
                                    border-left-color: $tab-border;
                                    border-right-color: $tab-border;
                                }
                            }
                        }
                    }
                    .tab-content{
                        padding: 0;
                        padding-top: 10px;
                    }
                }
                /*tabs-custom*/
            }
        }
        .about-all-list{
            display:block;
        }
        @media screen and (max-width: 767px){
            .about-all-list{
                display:none;
            }
        }
        /*timeline-ends*/
    }
    /*.profile-timeline--ends*/
    @media screen and (max-width: 767px){
        float: left;
        width: 100%;
        padding-left: 7px;
    }
    a{
        cursor: pointer;
    }
}
.right-sidebar{
    @media screen and (max-width: 767px){
        float:left;
        padding-left: 7px;
        padding-right: 1px;
    }
}
/*common css*/
.show{
    display: block;
}
.hide{
    display: none;
}
