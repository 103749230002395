.error-page-content {
  background-color: #FFFFFF;
  margin-right:0px;

  .button-content{
    display:inline-block;
  }
  .join-us-header {
    padding: 20px 40px;

    .join-us-title {
      font-size: 20px;
      color: #119ec9;
      font-weight: bold;

      b {
        color: #39bfe8;
        font-weight: bold;
      }
    }

    .join-us-description {
      font-size: 14px;
      color: #525f6a;
      padding-top: 5px;

      b {
        font-weight: bold;
      }
      .join-us-button {
        margin-top: 0;
        display: inline-block;
        margin-left: 50px;
        width: 150px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        background: #41c8f2;
        border-radius: 30px;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
        outline: none;
        text-transform: uppercase;
      }
      .learn-more-button{
        margin-top: 0;
        display: inline-block;
        margin-left: 10px;
        width: 150px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        background: #F2A941;
        border-radius: 30px;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
        outline: none;
        text-transform: uppercase;
      }
    }
  }

  .error-content {
    width: 100%;
    background-color: #eef3f6;
    padding: 1px;
    height: 683px;
    background-image: url(/img/fuzzy-error-page.png);
    background-repeat: no-repeat;
    background-size: 514px 680px;
    background-position: 100% 0;

    .error-content-info {
      display: inline-block;
      width: 500px;
      padding: 50px 40px;

      h2 {
        color: #39bfe8;
        font-size: 50px;
        letter-spacing: 5px;

      }
      span {
        display: block;
        color: #39bfe8;
        font-size: 14px;
        padding-top:10px;
      }
      p {
        color: #0090bc;
        font-size: 14px;
        padding-top:10px;
      }
    }
  }

  .join-us-content {
    background-color: #eef3f6;
    padding: 15px 30px;

    .real-platform {
      font-size: 19px;
      color: #39bfe8;
    }
    h3 {
      font-size: 19px;
      color: #0090bc;
      font-weight: normal;
    }
    .join-us-content-info {
      color: #525f6a;
      font-size: 14px;
      width:60%
    }
    .price-content {
      margin: auto;
      text-align: center;
      .price {
        font-size: 14px;
        color: #525f6a;
        text-align: center;
      }
      .join-now-btn {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: #41c8f2;
        color: #fff;
        font-size: 33px;
        padding: 1px 49px;
        border-radius: 30px;
      }
      .learn-more-btn{
        border: none;
        outline: none;
        cursor: pointer;
        margin-top:10px;
        background-color: #F2A941;
        color: #fff;
        font-size: 33px;
        padding: 1px 35px;
        border-radius: 30px;
      }
    }

  }

}
@media(max-width: 767px) {
  .button-content{
    width:100%
  }
  .error-content {
    width: 100%;
    background-color: #eef3f6;
    padding: 1px;
    height: 683px;
    background-image: url('/img/fuzzy-error-page.png');
    background-repeat: no-repeat;
    background-size: 450px 578px ;
    background-position:100% 0;
  }
  .error-content-info {
    display: inline-block;
    width: 300px;
    padding: 50px 40px;

    h2 {
      color: #39bfe8;
      font-size: 32px;
      letter-spacing: 5px;

    }
    span {
      display: block;
      color: #39bfe8;
      font-size: 12px;
      padding-top: 10px;
    }
    p {
      color: #0090bc;
      font-size: 12px;
      padding-top: 10px;
    }
  }
  .join-now-btn {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #41c8f2;
    color: #fff;
    font-size: 28px;
    padding: 1px 49px;
    border-radius: 30px;
  }
  .learn-more-btn{
    border: none;
    outline: none;
    cursor: pointer;
    margin-top:10px;
    background-color: #F2A941;
    color: #fff;
    font-size: 28px;
    padding: 1px 35px;
    border-radius: 30px;
  }

}
@media(max-width: 415px){
  .learn-more-button{
    display: block !important;
    margin: 6px auto !important;
    width: 150px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    background: #F2A941;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    outline: none;
    text-transform: uppercase;
  }
  .join-us-button {
    display: block !important;
    margin: 6px auto !important;
  }
  .error-page-content {

    .join-us-header {
      padding: 15px;
    }

    .join-us-content {
      margin-top: 20px;

      .price-content {
        .join-now-btn {
          font-size: 22px;
        }
        .learn-more-btn {
          font-size: 22px;
        }
      }
    }
    .error-content {
      background-size: 200px 294px;
      height: 350px;

      .error-content-info {
        padding: 10px;
        width:inherit;


        h2 {
          font-size: 25px;
        }
        span {
          width: 120px;
          font-size: 13px;
        }
        p {
          width: 180px;
          font-size: 13px;
        }
        .question {
          width: 230px;
        }
      }
    }
  }
}

@media (max-width: 321px) {
  .join-us-content {
    .price-content {
      .join-now-btn {
        font-size: 22px;
      }
      .learn-more-btn {
        font-size: 22px;
      }
    }
  }
  .error-content {
    background-size: 160px 225px !important;

    .error-content-info {
      padding: 10px;

      span {
        padding-top: 6px;
      }
      p {
       padding-top: 6px;
      }
    }
  }
}
