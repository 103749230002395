.agency-bar {
  width: 100%;
  margin-top: 0px;
  padding-top: 10px;
  clear: both;
  color: $color-gray-1;
  &__left {
    width: 120px;
    margin-right: 3px;
    padding: 3px;
    float: left;
    &__header {
      font-size: 11px;
      color: #97a8b5;
    }
    &__title {
      width: 50px;
      float: left;
      font-size: 14px;
      line-height: 25px;
      color: #586c7c;
    }
    &__money {
      color: #39bfe8;
    }
  }

  &__right {
    width: calc(100% - 130px);
    padding-bottom: 5px;
    float: left;
    margin-right: 3px;
    &__violation {
      width: 10%;
      float: left;
      text-align: center;
      border-left: 1px solid #efefef;
      &--double {
        width: 20%;
        .agency-bar__right__violation__title-small {
            width: 50%;
            float: left;
        }
        .agency-bar__right__violation__count {
          width: 50%;
          float: left;
        }
      }
      &__title {
        font-size: 14px;
        line-height: 25px;
        color: #97a8b5;
      }
      &__title-small {
        font-size: 8px;
        line-height: 9px;
         margin-bottom: 5px;
        color: #97a8b5;
        text-transform: uppercase;
      }
      &__count {
        line-height: 16px;
        font-weight: 400;
        font-size: 18px;
        color: #39bfe8;
      }
      &__balance {
        height: 11px;
        color: $color-gray-2;
        font-size: 8px;
        line-height: 12px;
        margin-top: 5px;
        &-red{
            color: red;
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .agency-bar {
    &__right {
      &__violation {
        min-height: 73px;
        width: 30%;
        &--double {
          width: 60%;
        }
      }
    }
  }
}
// @media (max-width: 480px) {
@media (max-width: 700px) {
  .agency-bar {
    &__left, &__right {
      width: 100%;
    }
    &__right {
      margin-top: 5px;
      &__violation {
        width: 20%;
        &--double {
          width: 40%;
        }
      }
    }
  }
}
@media (max-width:991px) {
  .agency-bar {
    &__left, &__right {
      width: 100%;
    }
    &__right {
      margin-top: 5px;
    }
  }
}

.h-relative-layout__search-result-box[max-width~="300px"],
.h-relative-layout__property-box[max-width~="300px"],
.h-relative-layout__dashboard-box[max-width~="300px"] {
  .agency-bar {
    &__right {
      &__violation {
        min-height: 73px;
        width: 30%;
        &--double {
          width: 60%;
        }
      }
    }
  }
}
.h-relative-layout__search-result-box[max-width~="480px"],
.h-relative-layout__property-box[max-width~="680px"],
.h-relative-layout__dashboard-box[max-width~="680px"] {
  .agency-bar {
    &__left, &__right {
      width: 100%;
    }
    &__right {
      margin-top: 5px;
      &__violation {
        width: 20%;
        &--double {
          width: 40%;
        }
      }
    }
  }
}
.h-relative-layout__search-result-box[max-width~="992px"],
.h-relative-layout__property-box[max-width~="992px"],
.h-relative-layout__dashboard-box[max-width~="992px"] {
  .agency-bar {
    &__balance {
      .agency-bar__left__header {
        width: auto;
        float: left;
        margin-right: 20px;
        font-size: 14px;
        line-height: 25px;
      }
      &-item {
        width: auto;
        float: left;
        margin-right: 12px;
        .agency-bar__left__title {
          width: auto;
          float: left;
          margin-right: 8px;
        }
      }
    }
    &__left, &__right {
      width: 100%;
    }
    &__right {
      margin-top: 5px;
    }
  }
}
.h-relative-layout__property-box[max-width~="992px"] {
  .hidden-xs {
    display: none !important;
  }
}
