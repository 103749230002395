$sky-blue:#37b1f3;

$black:#333;
$white:#fff;
$light-sky-blue-bg:#dff4ff;
$light-yellow:#fef79b;
$dark-blue:#346bc0;
$light-grey-bg:#ebebeb;
$blue-btn:#0e9eda;
$sky-blue-text:#11aff0;
$dark-grey-text:#484848;
$border-blue:#2c66bd;

// General PP variables
$pp-blue-color: #41c8f2;
$pp-bg-color: #ecf0f5;
