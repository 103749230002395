@import "../vendor/bootstrap/variables";
.login-page {
    font-size: 16px;
    .login-box {
        width: 400px;
    }
    .login-box-body {

        .checkbox.icheck {
            margin-top: 5px;
        }
        .form-control {
            font-size: 16px;
        }
        .btn{
            font-size: 16px;
        }
    }
}

.form-group.has-error {
    .help-block{
        font-size: 12px;
        strong {
            font-weight: 300;
        }
    }
}

.register-page {
    overflow: scroll;
    font-size: 16px;
    font-family: $font-family-open-sans !important;
    .register-box {
        width: auto;
        max-width: 900px;
        .register-box-body {
            background: none;
            padding: 0;
            .login-box-msg {
                font-family: $font-family-open-sans;
                font-weight: 300;
                text-align: left;
                padding: 0px 0px 20px 0px;
                display: block;
            }
            h2.login-box-msg {
                padding-bottom: 7px;
            }
            .box {
                padding: 20px;
                label {
                    font-weight: 300;
                    font-size: 14px;
                    margin-bottom: 2px;
                }
                &.features {
                    font-size: 16px;

                    ul {
                        padding-left: 0px;
                        list-style: none;
                        li {
                            line-height: 24px;
                            font-size: 14px;
                        }

                    }
                }

                &.experience {
                    h3.login-box-msg {
                        padding-bottom: 5px;
                    }
                    h4.login-box-msg {
                        padding-bottom: 20px;
                    }

                    form {
                        h3 {
                            font-size: 21px;
                            font-weight: 300;

                            &.w-sub {
                                margin-bottom: 0;
                                padding-bottom: 0;
                            }
                        }
                        .social {
                            padding: 15px 0;

                            .linkedin,
                            .facebook {
                                font-size: 14px;
                            }

                            .linkedin .fa {
                                padding-left: 6px;
                                font-size: 20px;
                            }

                            .facebook {
                                background: #28609b;
                                color: white;
                                .fa {
                                    color: white;
                                    font-size: 20px;
                                    padding-right: 6px;
                                }
                            }
                        }

                        h4 {
                            font-size: 16px;
                            font-family: $font-family-open-sans;
                        }

                        .well + a {
                            font-size: 20px;
                            display: block;
                            margin-top: -15px;
                        }

                        .well {
                            .styled-checkbox + label:before {
                                background: white;
                            }
                        }
                    }
                }

                .roles {
                    .checkbox {
                        padding: 2px 0px 4px 4px;
                        -webkit-box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.75);
                        -moz-box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.75);
                        box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.75);
                        margin-bottom: 20px;
                    }
                    .styled-checkbox + label:before {
                        background: #bbe8fb;
                    }
                }
            }
        }
        .disclaimer {
            font-size: 12px;
        }
    }
    .form-control {
        font-size: 16px;
    }
    .btn{
        font-size: 16px;
    }
}

.cursor{
    cursor: pointer;
}
.signupnow_button_1 {
    &:hover {
        color: white;
    }
}
.modal-content {
    color: #666;


    .login-box-msg {
        font-family: $font-family-open-sans;
        font-weight: 300;
        text-align: left;
        padding: 0px 0px 20px 0px;
        display: block;
    }
    h2.login-box-msg {
        padding-bottom: 7px;
    }
    .box {
        padding: 20px;
        label {
            font-weight: 300;
            font-size: 14px;
            margin-bottom: 2px;
        }
        &.features {
            font-size: 16px;

            ul {
                padding-left: 0px;
                list-style: none;
                text-align: left;
                li {
                    line-height: 24px;
                    font-size: 14px;
                }

            }
        }

        &.experience {
            h3.login-box-msg {
                padding-bottom: 5px;
            }
            h4.login-box-msg {
                padding-bottom: 20px;
            }

            form {
                h3 {
                    font-size: 21px;
                    font-weight: 300;

                    &.w-sub {
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
                .social {
                    padding: 15px 0;

                    .linkedin,
                    .facebook {
                        font-size: 14px;
                    }

                    .linkedin .fa {
                        padding-left: 6px;
                        font-size: 20px;
                    }

                    .facebook {
                        background: #28609b;
                        color: white;
                        .fa {
                            color: white;
                            font-size: 20px;
                            padding-right: 6px;
                        }
                    }
                }

                h4 {
                    font-size: 16px;
                    font-family: $font-family-open-sans;
                }

                .well + a {
                    font-size: 20px;
                    display: block;
                    margin-top: -15px;
                }

                .well {
                    .styled-checkbox + label:before {
                        background: white;
                    }
                }
            }
        }

        .roles {
            .checkbox {
                padding: 2px 0px 4px 4px;
                -webkit-box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.75);
                box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.75);
                margin-bottom: 20px;
            }
            .styled-checkbox + label:before {
                background: #bbe8fb;
            }
        }
    }

    .col-md-6 .box {
        padding: 20px;
        position: relative;
        background: #ffffff;
        margin-bottom: 20px;
        width: 100%;
        border: none;
        box-shadow: none;

        h2 {
            padding-bottom: 7px;
            font-size: 23px;
        }

        .login-box-msg {
            font-family: "Open Sans", sans-serif;
            font-weight: 300;
            text-align: left;
            padding: 0px 0px 20px 0px;
            display: block;
        }
        button {
            font-size:16px;
        }
    }

}
.top_nav_link {
    &:hover {
        color: #fff;
    }
}
