*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.carousel {
  .carousel-inner {
    width: 100%;

    .item {
      margin: auto;
      padding: 26px 160px;
    }
  }

}


.dropbtn {
  color: #fff;
  text-align: center;
  text-decoration: none;
}
.dropdown {
  position: relative;
}
.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: rgba(23, 108, 139, 0.9);
  border: 4px solid #3e9dbe;
  width: 180px;
  height: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

  div {
    padding-left: 10px;

    p {
      margin-top: -13px;
      margin-bottom: -14px;
    }
    span {
      font-size: 12px;
      width: 135px;
      line-height: 14px;
      display: inline-block;

    }
  }
  .ff-GateGuard{
    height: 78px;
  }
  .ff-PropertyPanel {
    height: 70px;
  }
  .ff-GateGuard, .ff-PropertyPanel {
    border-bottom: 4px solid #3e9dbe;
  }
  .ff-SubletSpy {
    p {
      margin-bottom: -28px;
    }
  }
}
.dropdown-content a {
  text-decoration: none;
}
.show {display:block;}






.friend-froud {
  width: 100%;

  .ff-content{
    width: 100%;

    .ff-header {
      width: 100%;
      height: 45px;
      background-color: #41bfe7;
      .ff-logo {
        width: 30%;
        height: 100%;
        display: inline-block;

        .ff-logo-img {
          background-image: url("/images/ff_images/logo.png");
          background-size: cover;
          width: 145px;
          height: 45px;
          float: right;
          margin-right: 50px;
        }
      }
      .ff-menu-bar {
        width: 69%;
        height: 100%;
        display: inline-block;
        background-color: #38a8cc;
        float: right;

        ul {
          float: right;
          margin-right: 20%;

          li {
            height: 45px;
            line-height: 45px;
            display: inline-block;
            list-style: none;
            padding: 0 20px;
            color: #fff;
            font-size: 16px;

            &:hover {
              background-color: #1685ac;
              cursor: pointer;
            }
            &:nth-last-of-type(1):hover {
              background-color: #38a8cc;
            }

            .ff-contact-btn {
              outline: none;
              border: 1px solid #fff;
              background: none;
              color: #fff;
              width: 86px;
              height: 30px;
              line-height: 30px;
              border-radius: 17px;
              cursor: pointer;

              &:hover {
                background-color: #1685ac;
              }
            }
          }
        }
      }
    }

    .ff-content-animate {


      .ff-property-panel-logo {
        width: 182px;
        height: 23px;
        background-image: url(/images/ff_images/property-panel-logo.png);
        background-size: cover;
        margin: 55px auto 30px auto;
      }
      p {
        width: 100%;
        text-align: center;
      }
      .ff-text {
        font-size: 20px;
      }
      .ff-animate-header {
        color: #77777f;
        font-size: 22px;
        margin-top: 5px;
      }
      .ff-laptop-content {
        width: 80%;
        margin: auto;
        position: relative;

        /*   .ff-laptop {
             width: 100%;
             height: 445px;
             !*background-image: url(/images/ff_images/laptop.png);*!
             background-size: cover;
             margin: 27px auto 30px auto;

             img {
               width: initial;
               height: inherit;
               display: block;
               margin: auto;
             }

           }*/
        .ff-sing-up-btns {
          width: 130px;
          position: absolute;
          bottom: 43px;
          right: -43px;

          .ff-learn-more, .ff-sign-up {
            outline: none;
            border: 1px solid #6b7b8c;
            background: none;
            background-color: rgba(252, 252, 252, 0.86);
            color: #6b7b8c;
            width: 225px;
            height: 60px;
            border-radius: 40px;
            font-size: 12px;
            cursor: pointer;
          }
          .ff-sign-up {
            margin-top: 10px;
          }
        }
      }
    }
    .ff-catalogs {


      .ff-catalog-option {
        display: table-cell;
        border:3px solid white;
        background-color: #EDEFEF;
        height: 300px;
        padding: 5px;
        //border-right: 5px solid #fff;
        //width: 25%;

        .ff-option-head {
          background-size: cover;
          margin: auto;
        }
        .ff-first {
          background-image: url(/images/ff_images/sublet-logo.png);
          width: 117px;
          height: 27px;
        }
        .ff-second {
          background-image: url(/images/ff_images/gateguard-logo.png);
          width: 152px;
          height: 28px;
        }
        .ff-third{
          background-image: url(/images/ff_images/propertypanel-logo.png);
          width: 154px;
          height: 18px;
        }
        .ff-fourth{
          background-image: url(/images/ff_images/the-world-logo.png);
          width: 151px;
          height: 13px;
        }

        .ff-option-description {
          color: #6b7b8c;
          font-size: 12px;
          text-align: center;
          margin-top: 15px;
        }
        .ff-option-img-content {
          width: 100%;
          height: 225px;
          .ff-option-img {
            margin: auto;
            margin-top: 10px;
            background-size: cover;
          }
          .ff-first-img {
            width: 241px;
            height: 208px;
            background-image: url(/images/ff_images/subletspy_img.png);
          }
          .ff-second-img {
            width: 105px;
            height: 218px;
            background-image: url(/images/ff_images/gateguard_img.png);
          }
          .ff-third-img {
            width: 340px;
            height: 200px;
            background-image: url(/images/ff_images/laptop.png);
          }
          .ff-fourth-img {
            width: 275px;
            height: 221px;
            background-image: url(/images/ff_images/number1_img.png);
          }
        }

      }
      .ff-catalog-option-last {
        border-right: none;
      }
    }
    .ff-updates {
      background-color: #6b7b8c;
      text-align: center;

      .ff-update-form {
        padding: 25px 0;

        div {
          padding: 0 15px;
        }

        span {
          font-size: 16px;
          color: #fff;
          margin: 8px 0;
          height: 42px;
          line-height: 42px;
        }
        input {
          margin: 8px 0;
          height: 42px;
        }
        input::-webkit-input-placeholder {
          color: #525e6b;
          font-size: 16px;
          text-align: center;
        }

        input:-moz-placeholder {
          color: #525e6b;
          font-size: 16px;
          text-align: center;
        }

        input::-moz-placeholder {
          color: #525e6b;
          font-size: 16px;
          text-align: center;
        }

        input:-ms-input-placeholder {
          color: #525e6b;
          font-size: 16px;
          text-align: center;
        }
        .ff-update-input {
          width: 200px;
          padding: 15px;
          color: #525e6b;
          font-size: 16px;
          text-align: center;
        }
        .ff-update-join {
          cursor: pointer;
        }
      }
    }
    .ff-help {
      background-color: #e9ebec;

      p {
        color: #6b7b8c;
        font-size: 27px;
        padding-top: 70px;
        text-align: center;
      }
      div {
        width: 100%;
        margin: 60px auto;
        text-align: center;

        button {
          min-width: 210px;
          margin: 12px 10px;
          padding: 10px 25px;
          outline: none;
          border: 1px solid #6b7b8c;
          background: none;
          background-color: rgba(252, 252, 252, 0.86);
          color: #6b7b8c;
          -webkit-border-radius: 40px;
          border-radius: 40px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
    .ff-news {
      background-color: #fff;

      .ff-news-header {
        padding-top: 70px;
        color: #6b7b8c;
        font-size: 32px;
        text-align: center;
      }

      .ff-news-animate {

        .ff-arrow-left{
          width: 52px;
          height: 52px;
          background-image: url(/images/ff_images/left-arrow-second.png);
          background-size: cover;
          display: inline-block;
          float: left;
          margin-top: 170px;
          cursor: pointer;
          margin-left: 10px;

          &:active {
            background-image: url(/images/ff_images/left-arrow-first.png);
          }
        }

        .ff-arrow-right {
          width: 52px;
          height: 52px;
          background-image: url(/images/ff_images/right-arrow-second.png);
          background-size: cover;
          display: inline-block;
          float: right;
          margin-top: 170px;
          cursor: pointer;

          &:active {
            background-image: url(/images/ff_images/right-arrow-first.png);
          }
        }

        .ff-news-logo {
          width: 1070px;
          height: 70px;
          margin: 35px auto 0 auto;

          div {
            display: inline-block;
            background-size: cover;
            vertical-align: middle;
            margin-right: 28px;
          }
          .ff-pando {
            width: 135px;
            height: 25px;
            background-image: url(/images/ff_images/pando-logo.png);
          }
          .ff-abc {
            width: 67px;
            height: 50px;
            background-image: url(/images/ff_images/abc-logo.png);
          }
          .ff-daily-news {
            width: 197px;
            height: 17px;
            background-image: url(/images/ff_images/daily-news-logo.png);
          }
          .ff-nbs {
            width: 52px;
            height: 51px;
            background-image: url(/images/ff_images/nbc-logo.png);
          }
          .ff-real-deal {
            width: 199px;
            height: 41px;
            background-image: url(/images/ff_images/real-deal-logo.png);
          }
          .ff-pix-news {
            width: 102px;
            height: 51px;
            background-image: url(/images/ff_images/pix-news-logo.png);
          }
          .ff-curbed {
            width: 88px;
            height: 45px;
            background-image: url(/images/ff_images/curbed-logo.png);
          }
        }

        .ff-news-content {
          margin-bottom: 10px;
          .ff-news-option {
            display: table-cell;
            margin: 0 17px;

            .ff-news-img {
              width: 325px;
              height: 232px;
              background-size: cover;
              margin-bottom: 8px;
            }
            .first {
              background-image: url(/images/ff_images/news-img1.png);
            }
            .second {
              background-image: url(/images/ff_images/news-img2.png);
            }
            .third {
              background-image: url(/images/ff_images/news-img3.png);
            }

            span {
              font-size: 22px;
              color: #6b7b8c;
            }
            p {
              font-size: 18px;
              color: #38a8cc;
              margin-top: 8px;
            }
          }
        }
      }
    }
    .ff-quotes {
      background-color: #eef3f6;

      .ff-quotes-header {
        font-size: 32px;
        color: #899099;
        padding: 55px 0 23px 0;
        text-align: center;
      }

      .ff-quotes-content {
        width: 94%;
        background-color: #fff;
        border-radius: 20px;
        margin: auto;
        margin-bottom: 15px;
        padding: 45px 97px 30px 97px;
        position: relative;

        p {
          font-size: 19px;
          color: #525e6b;

          text-align: center;
          line-height: 35px;
        }

        .quote-icon {
          position: absolute;
          width: 43px;
          height: 37px;
          background-size: cover;
          background-image: url(/images/ff_images/arrow1.png);
        }
        .left {
          top: 25px;
          left: 20px;
        }
        .right {
          top: 25px;
          right: 20px;
          -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
        }
      }
      .ff-quote-name {
        padding-top: 25px;
      }
      span {
        text-align: center;
        display: block;
        font-size: 18px;
        color: #6b7b8c;
        padding-top: 5px;
        margin-bottom: 7px
      }

      ul {
        list-style: none;
        margin-top: 35px;
        text-align: center;

        li {
          width: 14px;
          height: 14px;
          background-color: #dee1e2;
          border-radius: 50%;
          display: inline-block;
          margin: 2px;

          &:hover {
            color: #d5d5d5;
            cursor: pointer;
          }
        }
      }
    }
    .ff-subletSpy {
      background-color: #fff;

      .ff-subletSpy-content {
        color: #525e6b;
        padding-left: 100px;
        padding-right: 120px;

        .header {
          padding: 100px 0 90px 0;
          font-size: 27px;
        }
        span {
          font-size: 24px;
          display: block;
          margin-bottom: 15px;
        }
        button {
          margin: 100px auto 50px auto;
          display: block;
          width: 186px;
          height: 48px;
          outline: none;
          border: 1px solid #39bfe8;
          background: none;
          background-color: #fff;
          color: #6b7b8c;
          border-radius: 40px;
          font-size: 18px;
          cursor: pointer;
        }
        ul {
          list-style: none;
          margin-top: 35px;
          text-align: center;

          li {
            width: 14px;
            height: 14px;
            background-color: #dee1e2;
            border-radius: 50%;
            display: inline-block;
            margin: 2px;

            &:hover {
              color: #d5d5d5;
              cursor: pointer;
            }
          }
        }
        .ff-header-content {
          font-size: 16px;
        }
      }
      .ff-subletSpy-img {
        height: 700px;
        position: relative;
        background-image: url(/images/ff_images/subletSpy.png);
        background-size: cover;

        .ff-subletSpy-logo {
          width: 313px;
          height: 90px;
          margin: auto;
          position: absolute;
          top: 40%;
          left: 30%;
          background-image: url(/images/ff_images/subletSpy-logo.png);
          background-size: cover;
        }
      }
    }

    .ff-aboutus {

      /*  height: 670px;*/
      background-color: #39bfe8;

      .ff-aboutus-content {
        color: #fff;
        padding-left: 200px;
        padding-right: 25px;
        padding-bottom: 15px;

        .header {
          padding: 115px 0 95px 0;
          font-size: 32px;
        }
        span {
          font-size: 24px;
          display: block;
          margin-bottom: 15px;
        }
        .ff-header-content {
          font-size: 16px;
        }
      }
      .ff-aboutus-imgs {

        div {
          margin-top: 80px;
          margin-bottom: 60px;
          width: 400px;
          height: 442px;
          vertical-align: middle;
          margin-left: 75px;
          background-image: url(/images/ff_images/about-us-imgs.png);
          background-size: cover;
        }


      }
    }

    .ff-footer {
      width: 100%;
      background-color: #eef3f6;
      padding: 40px 90px 0 90px;
      display: flex;

      .ff-footer-solution {
        margin-left: 110px;
        width: 260px;
        display: inline-block;

        .solution-header {
          color: #6b7b8c;
          font-size: 20px;
        }

        .solution-content {
          margin-top: 23px;

          span {
            color: #262c32;
            font-size: 12px;
          }

          p {
            color: #6b7b8c;
            font-size: 12px;
            padding-top: 5px;
          }
        }
      }

      .ff-footer-about {
        margin-left: 130px;
        width: 130px;
        display: inline-block;

        .about-header {
          color: #6b7b8c;
          font-size: 20px;
        }

        ul {
          list-style: none;

          li {
            padding-top: 23px;
            color: #262c32;
            font-size: 12px;
          }
        }
      }

      .ff-footer-request {
        margin-left: 130px;
        display: inline-block;
        width: 650px;
        height: 225px;
        background-color: #e6ebee;

        button {
          margin: auto;
          display: block;
          width: 176px;
          height: 40px;
          outline: none;
          border: 1px solid #6b7b8c;
          background: none;
          background-color: rgba(252, 252, 252, 0.86);
          color: #6b7b8c;
          border-radius: 40px;
          font-size: 14px;
          cursor: pointer;
        }
        .ff-footer-first-btn {
          margin-top: 60px;
        }
        .ff-footer-second-btn {
          margin-top: 30px;
        }
      }
    }

    .ff-footer-links {
      width: 100%;
      height: 60px;
      background-color: #7e7e7e;

      ul {
        list-style: none;
        margin-left: 200px;

        li {
          display: inline-block;
          padding-top: 23px;
          color: #e9ebec;
          font-size: 10px;
          margin-right: 15px;
        }
      }
    }
  }
}


/*@media (max-width : 500px) {
  .ff-catalogs {
    //background-color: #EDEFEF;
    //width: 100%;
    //display: table;
    height: 312px;

    .ff-catalog-option {
      display: table-cell;
      border:1px solid white;
      background-color: #EDEFEF;
      height: 250px;
      padding-top:5px;
      //border-right: 5px solid #fff;
      //width: 25%;

      .ff-option-head {
        background-size: cover;
        margin: auto;
      }
      .ff-first {
        background-image: url(/images/ff_images/sublet-logo.png);
        width: 117px;
        height: 27px;
      }
      .ff-second {
        background-image: url(/images/ff_images/gateguard-logo.png);
        width: 152px;
        height: 28px;
      }
      .ff-third{
        background-image: url(/images/ff_images/propertypanel-logo.png);
        width: 154px;
        height: 18px;
      }
      .ff-fourth{
        background-image: url(/images/ff_images/the-world-logo.png);
        width: 151px;
        height: 13px;
      }

      .ff-option-description {
        color: #6b7b8c;
        font-size: 12px;
        text-align: center;
        margin-top: 15px;
      }
      .ff-option-img-content {
        width: 100%;
        height: 225px;
        .ff-option-img {
          margin: auto;
          margin-top: 10px;
          background-size: cover;
        }
        .ff-first-img {
          width: 187px;
          height: 160px;
          background-image: url(/images/ff_images/subletspy_img.png);
        }
        .ff-second-img {
          width: 105px;
          height: 218px;
          background-image: url(/images/ff_images/gateguard_img.png);
        }
        .ff-third-img {
          width: 260px;
          height: 150px;
          background-image: url(/images/ff_images/laptop.png);
        }
        .ff-fourth-img {
          width: 200px;
          height: 170px;
          background-image: url(/images/ff_images/number1_img.png);
        }
      }

    }
    .ff-catalog-option-last {
      border-right: none;
    }
  }



}*/

@media (max-width: 945px) {
  .ff-laptop img {
    width: 100% !important;
    height: auto !important;
  }
}
@media (min-width: 840px) {
  .ff-update-form {

    .ff-get-update, .ff-update-input:nth-of-type(1) {
      text-align: right;
    }
    .ff-update-join, .ff-update-input:nth-of-type(2) {
      text-align: left;
    }
  }
}
@media (min-width: 768px) and (max-width: 815px) {
  .ff-update-form {

    input {
      width: 170px !important;
    }
  }
}

.ff-subletSpy .carousel .carousel-inner .item {
  padding: 10px !important;
}
@media (max-width: 1400px) {
  .ff-aboutus-content {
    padding-left: 40px !important;
    padding-right: 20px !important;
  }
  .ff-subletSpy {

    .ff-subletSpy-content {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}
@media (max-width: 500px) {
  .ff-aboutus-content {
    .header {
      padding: 26px 0 18px 0 !important;
      font-size: 23px !important;
    }
    span {
      font-size: 20px !important;
    }
    .ff-header-content {
      font-size: 12px !important;
    }
  }
  .ff-aboutus-imgs {
    div {
      width: 262px !important;
      height: 225px !important;
      margin-left: 20px !important;
      margin-top: 5px !important;
      margin-bottom: 30px !important;
    }
  }
  .ff-subletSpy {
    .ff-subletSpy-content {
      padding-left: 5px !important;
      padding-right: 5px !important;
      .header {
        padding: 10px 0 10px 0 !important;
        font-size: 20px !important;
      }
      span {
        font-size: 18px !important;
      }
      button {
        margin: 30px auto 15px auto !important;
      }
      .ff-header-content {
        font-size: 13px !important;
      }
    }

    .ff-subletSpy-img {
      height: 300px !important;

      .ff-subletSpy-logo {
        width: 193px !important;
        height: 55px !important;
      }
    }
  }
  .ff-help {
    p {
      padding-top: 25px !important;
    }
    div {
      margin: 5px auto !important;
    }
  }
  .ff-quotes {
    span {
      font-size: 10px !important;
    }
    .ff-quotes-header {
      font-size: 20px !important;
      padding: 5px 0 5px 0 !important;
    }
    .ff-quotes-content {
      padding: 17px 38px 15px 38px !important;
      p {
        font-size: 9px !important;
        line-height: 12px !important;
      }
      .quote-icon {
        width: 10px !important;
        height: 10px !important;
      }
    }
    .ff-quote-name {
      padding-top: 2px !important;
    }
  }
}
@media (max-width: 910px) {
  .carousel {
    .carousel-inner {

      .item {
        margin: auto;
        padding: 26px 30px !important;
      }
    }
  }

  .ff-sing-up-btns {
    right: -126px !important;

    .ff-learn-more, .ff-sign-up {
      width: initial !important;
      height: initial !important;
      border-radius: 40px !important;
      font-size: 10px !important;
      padding: 13px 24px;
    }
    .ff-sign-up {
      margin-top: 10px;
    }
  }
}
@media (max-width: 500px) {

  .ff-sing-up-btns {
    position: relative !important;
    width: initial !important;
    text-align: center;
    bottom: 10px !important;
    right: auto !important;

    .ff-learn-more, .ff-sign-up {
      width: initial !important;
      height: initial !important;
      border-radius: 40px !important;
      font-size: 10px !important;
      padding: 13px 24px;
    }
    .ff-sign-up {
      margin-top: 10px;
    }
  }

}
.multi-item-carousel{
  .carousel-inner{
    .item img {
      width: 300px;
      height: 200px;
    }
    > .item{
    }
    .active{
      &.left{
        left:-33%;
      }
      &.right{
        left:33%;
      }
    }
    .next{
      left: 33%;
    }
    .prev{
      left: -33%;
    }
    /* @media all and (transform-3d), (-webkit-transform-3d) {
       > .item{
         // use your favourite prefixer here
         backface-visibility: visible;
         transform: none!important;
       }
     }*/
  }
  .carouse-control{
    &.left, &.right{
      background-image: none;
    }
  }
}

@media (max-width: 500px) {
  .ff-news {
    .ff-news-header {
      padding-top: 10px !important;
    }
    .ff-news-option {
      span {
        font-size: 10px !important;
      }
      p {
        font-size: 9px !important;
      }
    }
  }
  .multi-item-carousel {
    .carousel-inner {
      .item img {
        width: initial;
        height: 60px;
      }
    }
  }

}

// non-related styling:
 


@media (max-width: 768px){
  .ff-footer-request{
    display: none !important;
  }
}

@media (max-width: 768px) {
  .ff-footer-solution {
    margin-left: 15px !important;
  }
}

@media (max-width: 375px){
  .ff-footer-solution{
    margin-left: 0px !important;
  }
}

@media (max-width: 321px){
  .ff-footer-solution{
    margin-left: -36px !important;
  }
}

@media (max-width: 768px) {
  .ff-footer-about {
    margin-left: 30px !important;
  }
}

@media (max-width: 321px) {
  .ff-footer-about {
    margin-left: 35px !important;
  }
}



@media (max-width: 768px) {
  .ff-footer-links ul  {
    margin-left: 105px !important;
  }
}

@media (max-width: 321px) {
  .ff-footer-links ul {
    margin-left: 46px !important;
  }
}

@media (max-width: 768px) {
  .ff-subletSpy-content .header {
    text-align: center;
    padding: 15px 0 5px 0 !important;
  }
}

@media (max-width: 768px) {
  .ff-subletSpy-content button {
    margin: 20px auto 25px auto !important;
  }
}

@media (max-width: 768px) {
  .ff-subletSpy-content span {
    text-align: center;
  }
}




