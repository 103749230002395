@import "blocks/form.scss";
@import "blocks/id-uploader.scss";
@import "blocks/table-grid.scss";
@import "blocks/devices.scss";
@import "blocks/subletspy.scss";

.b-new {
  &__image-container {
    position: relative;
    height: 300px;
    width: 100%;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.b-welcome {
  &__img-box {
    text-align: center;
  }
  &__img {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    height: auto;
    max-height: 220px;
  }
}

.b-my-team {
  box-shadow: none;
  border-radius: 0;
  &__tabs {
    padding-bottom: 0 !important;
    > li > a {
      text-transform: uppercase;
    }
    > li:first-of-type.active > a {
      border-left-color: #f4f4f4 !important;
    }
  }
  &__action-dd {
    .dropdown-menu {
      right: 0;
      left: auto;

      background-color: #41c8f2;
      color: #ffffff;
      border-color: #41c8f2;
      border-radius: 0;
      a {
        color: #ffffff;
        &:hover,
        &:focus,
        &:active {
          background-color: #199bc3;
          color: #fff;
        }
      }
    }
  }
  &__action-btn {
    display: inline-block;
    width: 60px;
    background-color: #41c8f2;
    border: 1px solid #39bfe8;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    padding: 4px 6px 2px;
    border-radius: 0;
    cursor: pointer;
  }
  &__true-icon {
    color: #168626;
    font-size: 16px;
  }
  &__false-icon {
    color: #ad1717;
    font-size: 16px;
  }
  &__table-wrapper {
    width: 100%;
  }
  table#{&}__table {
    width: 100%;
    min-width: 100%;
    .sorting_desc:after {
      top: 11px;
    }
    th, td {
      padding: 8px;
      text-align: center;
      border-color: rgba(111,123,130,.1);
    }
    th {
      padding: 12px 30px 12px 8px;
      border-bottom: 2px solid #f4f4f4;
    }
    td {
      border-top: 1px solid #f4f4f4;
    }
    th.b-my-team__avatar-td,
    td.b-my-team__avatar-td {
      width: 76px;
      box-sizing: border-box;
    }
    th.b-my-team__action-td,
    td.b-my-team__avatar-td {
      width: 76px;
      box-sizing: border-box;
    }
    tr {
      &.odd {
        background-color: #f9f9f9;
      }
    }
  }

  table.table-bordered.dataTable {
    border-collapse: collapse !important;
  }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }
  .dataTables_scrollHead &__table {
    margin-top: 0px !important;
  }
  .dataTables_scrollHeadInner {
    min-width: 100%;
  }
  .dataTables_scrollBody &__table {
    //
  }
  .dataTables_scrollBody {
    //
  }
}

.b-help {
  &__items {
    margin-bottom: 30px;
  }
  &__item {
    //
  }
  &__header {
    font-size: 16px;
    padding: 10px 0;
    color: #41c8f2;
    cursor: pointer;
  }
  &__desc {
    font-size: 14px;
  }

  .panel {
    border-radius: 0;
    box-shadow: none;
    border: none;
  }
  .panel-heading {
    border-radius: 0px;
    min-height: 0 !important;
    padding: 4px 0;
    background-color: transparent;
    border: none;
  }
  .panel-title {
    font-size: 16px;
    color: #0090bc;
    font-weight: normal;
    &:hover {
      color: darken(#0090bc, 7%);
    }
  }
  .panel-info {

  }
  .panel-body {
    font-size: 14px;
    p:last-child {
      margin-bottom: 0;
    }
    ul {
      padding-left: 30px;
    }
  }
  .panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: none;
  }
  &__h2 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #6b8091;
  }
  &__h3 {
    font-size: 16px;
    margin-bottom: 20px;
    color: #6b8091;
  }
  &__support-btn {
    padding: 8px 12px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  &__email-us {
    font-size: 14px;
    margin-bottom: 20px;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.b-invite {
  // background: #eee;
  // padding: 20px;
  color: #666;
  margin-bottom: 20px;
  // border: 1px solid #999;
  &__form {
    .form-control {
      font-size: 16px;
      height: auto;
    }
    .btn {
      font-size: 16px;
    }
  }
  &__form-title {
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    color: #41c8f2;
  }
  &__form-text {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 1.3;
    font-weight: 300;
    &.-last {
      margin-bottom: 15px;
    }
  }
  &__btn-container {
    text-align: center;
    .btn {
      min-width: 120px;
    }
  }
}

.h-relative-layout__invite-box[max-width~="992px"] {
  .col-md-4 {
    width: 100%;
  }
  .b-invite {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
