body > #chat-window-boxes-wrapper {
  position: relative;
  z-index: 1260;
}
.desk-chat {
  font-weight: normal;
  padding-left: 40px;
  bottom: -2px;
  position: fixed;
  overflow: hidden;
  overflow-x: scroll;
  z-index: 990;
  right: 0;
  height: 40px;
  overflow: visible;
}
.desk-chat--container {
  text-align: right;
}
.desk-chat--item {
  width: 308px;
  height: 384px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 20px;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  text-align: left;
  margin-top: -344px;

  .box-body, .direct-chat-messages {
    height: 289px;
  }

  .item-box-wrapper {
    padding:0px;
  }
}
.desk-chat--item.min {
  height: 45px !important;
  margin-top: 0px !important;
}

#chat-window-boxes-wrapper .box {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #869bac !important;
    .direct-chat-text:before, .direct-chat-text:after {
        display:none;
    }
    .direct-chat-name {
        font-size:10px;
    }
    .direct-chat-msg {
        .direct-chat-text {
            background: #eef3f6;
            border-color: #eef3f6;
            color: #000000;
            font-size: 12px;
            line-height: 1.5;
            overflow-wrap: break-word;
            overflow: hidden;
        }
    }
    .box-header {

        border-bottom: 1px solid #39bfe8;
        .btn-box-tool {
            padding:0px;
            i {
                color:#39bfe8;
            }
        }
        h3 {
            font-weight: 300;
            font-size:14px;
            color:#39bfe8;
        }
    }
}
