[v-cloak] {
  display: none;
}

// Some overrides for Calendar
.fc-toolbar {
  padding: 10px 0;
}

@include breakpoint(xs) {
  .fc-toolbar {
    h2 {
      font-size: 18px;
    }
  }
}

.f-cat-links {
  a {
    color: #e2e5e6;

    &:hover, &:active {
      color: #f3f5f7;
    }
  }
}
