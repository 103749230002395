/* Loading Animation: */
.vuetable-wrapper {
  opacity: 1;
  position: relative;
  filter: alpha(opacity=100); /* IE8 and earlier */
}
.vuetable-wrapper.-loading {
  opacity:0.4;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
}
.vuetable-wrapper.-loading:after {
  position: absolute;
  content: '';
  top: 40%;
  left: 50%;
  margin: -30px 0 0 -30px;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  border: 4px solid #000;
  height: 60px;
  width: 60px;
  background: transparent !important;
  display: inline-block;
  -webkit-animation: pulse 1s 0s ease-in-out infinite;
          animation: pulse 1s 0s ease-in-out infinite;
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
         border-width: 12px; }
  100% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6); }
}
