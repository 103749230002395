.news-block{
    .news_link{
        color: $color-light-gray-7;
    }
    &__row {
        display: flex;
        flex-flow: row wrap;
    }
    &__title{
        float: left;
        color: $color-light-gray-4;
        &__right{
            float:right;
            color: $color-light-gray-4;
            padding-left: 10px;
            &--red{
                color: red;
            }
        }
    }
    &__card{
        display: inline-flex;
        padding-top: 10px;
        margin-bottom: 6px;
        background: #eef3f6;
        padding: 3px;
        width: 48.9%;
        float: left;
        &:nth-child(2n){
            margin-left: 2%;
        }

        &__left{
            float: left;
            width: 80px;
            height: 64px;
            margin-right: 10px;
            &__image{
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        &__right{
            color: #c3cbd1;
            width: calc(100% - 90px);
            float: right;
            position: relative;
            &__header{
                font-size: $font-size-base;
                line-height: 15px;
                margin-bottom: 16px;
                overflow: hidden;
                height: 45px;
                width: 100%;
            }
            &__source{
                text-align: right;
                font-size: $font-size-base;
                color: #aeaeae;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1200px){
    .news-block{
        &__card{
            width: 100%;
            margin-left: 0px !important;
        }
    }
}
@media (max-width: 415px){
    .news-block__title__right{
        font-size:6px;
    }
    .footer_icons{
        text-align:center;
    }
    .news-block{
        &__card{
            width: 100%;
            margin-left: 0px !important;

        }
    }
}
